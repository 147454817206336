export const ChatbotIframe = () => {
    return (
        <iframe
            id="bpFull"
            className="flex flex-1 w-full max-h-screen h-full md:h-[150%] border"
            srcDoc={`<!DOCTYPE html><html><body>
            <script src='https://cdn.botpress.cloud/webchat/v2.2/inject.js'></script>
            <script src='https://files.bpcontent.cloud/2024/10/24/13/20241024134328-THCIMES1.js'></script>
            <script>
                // Open webchat
                window.botpress.on('webchat:ready', (conversationId) => { botpress.open(); });
                window.botpress.on('conversation', (conversationId) => { botpress.open(); });
                window.botpress.on('webchat.open', (conversationId) => { 
                
                });
            </script>
            <style>
                @media (min-width:768px) {
                .bpWebchat { width: 100%; height: 100%; right: 0; bottom: 0; max-height: 100%; overflow: auto; -webkit-overflow-scrolling: touch; }
                }
            </style>
            </body></html>`}
        ></iframe>
    );
}