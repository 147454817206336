import React, { useState, useEffect, useMemo } from "react";
import { auth } from "../../firebase/config";
import AddChatButton from "./addChatButton";
import AddFolderButton from "./addFolderButton";
import ToggleSidebarButton from "./toggleSidebar";
import { folderService } from "../../services/folder";
import { chatService } from "../../services/chat";
import FolderItem from "./folder";
import { Chat, Folder } from "../../types/index";
import ChatItem from "./chat";
import { useChatContext } from "../../context/chatContext";
import { userService } from "../../services/user";
import { useDrag, useDragLayer, useDrop } from "react-dnd";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { SignOutSVG } from "./signoutSVG";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/Haivn_Logo_Finals_Opt 1 White.png";
import logoFolder from "../../assets/folderly-folder-logo.png";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { Trash2 } from "lucide-react";
import { FaClock } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

import Tooltip from "../general/tooltip";

import { useSwipeable } from "react-swipeable";

interface SidebarProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

declare global {
	interface Window {
		searchTimeout: NodeJS.Timeout;
	}
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, setIsOpen }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isMobileState, setIsMobileState] = useState(false);
	const [expandedFolders, setExpandedFolders] = useState<string[]>([]);
	const [newFolderName, setNewFolderName] = useState<string>("");
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [matchingChats, setMatchingChats] = useState<Chat[]>([]); // State to hold matching chats

	//Hanlde the swipe gestures
	const handlers = useSwipeable({
		onSwipedLeft: () => {
			setIsOpen(false);
		},
		onSwipedRight: () => {
			setIsOpen(true);
		},
		trackMouse: false, // Ensure only touch gestures are tracked
		preventScrollOnSwipe: true,
		delta: 10,
	});

	// State for AI toggle
	const [aiType, setAiType] = useState<"Gemini" | "GPT 4o">("GPT 4o"); // Default to GPT 4o
	// States for debounced search

	const {
		folders,
		setFolders,
		chatsOutsideFolders,
		setChatsOutsideFolders,
		activeChat,
		setActiveChat,
		activeFolderId,
		setActiveFolderId,
		tokenCount,
		setTokenCount,
		folderChats,
		setFolderChats,
		setSubFolders,
		setManageHistory,
		manageHistory,
	} = useChatContext();

	// Debounce the search term
	const [isSearching, setIsSearching] = useState(false);

	//Selected chats to delete
	const [selectedChats, setSelectedChats] = useState<string[]>([]);

	const handleSetManageHistory = () => {
		setManageHistory(!manageHistory);
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);

		if (e.target.value.length >= 3) {
			// Clear any existing timeout
			if (window.searchTimeout) {
				clearTimeout(window.searchTimeout);
			}

			// Set new timeout
			window.searchTimeout = setTimeout(async () => {
				try {
					setIsSearching(true);
					const idToken = await auth.currentUser?.getIdToken();
					if (idToken) {
						const chats = await chatService.searchChats(
							e.target.value,
							idToken,
						);
						setMatchingChats(chats);
					}
				} catch (error) {
					console.error("Error searching chats:", error);
				} finally {
					setIsSearching(false);
				}
			}, 500);
		} else {
			setMatchingChats([]); // Clear matches if search term is too short
		}
	};

	useEffect(() => {
		// Function to check the screen size
		const handleResize = () => {
			const isMobile = window.innerWidth <= 768;
			setIsMobileState(isMobile);

			// Close the sidebar if switching to mobile view
			if (isMobile) {
				setIsOpen(false);
			}
		};

		handleResize();

		// Listen for window resize events
		window.addEventListener("resize", handleResize);

		// Cleanup listener on unmount
		return () => window.removeEventListener("resize", handleResize);
	}, [setIsOpen]);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	// Close the sidebar when a chat is selected or added on mobile
	useEffect(() => {
		if (activeChat && isOpen && isMobileState) {
			setIsOpen(false);
		}
	}, [activeChat]);

	const [runTour, setRunTour] = useState(false);

	const steps = [
		{
			target: ".folder-controls", //located in addFolderButton.tsx
			content: "Create new folders to organize your conversations",
			disableBeacon: true,
		},
		{
			target: ".chat-controls", //located in addChatButton.tsx
			content: "Start a new chat here",
		},
		{
			target: ".sidebar-toggle", //located in toggleSidebar.tsx
			content: "Toggle the sidebar to give your chat more space",
		},
		{
			target: ".ai-toggle", //located in topbar.tsx
			content: "Switch between different AI models - GPT-4 or Gemini Pro",
		},
		{
			target: ".search-chats",
			content: "Search through all your conversations",
		},
		{
			target: ".account-controls", //located in topbar.tsx
			content: "Manage your account and view saved clips",
		},
	];

	useEffect(() => {
		const hasSeenTour = localStorage.getItem("hasSeenTour");
		if (!hasSeenTour) {
			setRunTour(true);
			localStorage.setItem("hasSeenTour", "true");
		}
	}, []);

	const [isInitialLoading, setIsInitialLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
			if (currentUser) {
				console.log("User authenticated:", currentUser);
				setIsInitialLoading(true); // Set loading true before fetching
				try {
					// Fetch all data in parallel
					const idToken = await auth.currentUser?.getIdToken();
					if (!idToken) return;

					const [userInfo, tokens, folders, chats] = await Promise.all([
						userService.getUserInfo(idToken),
						userService.getUserTokens(),
						folderService.getFolders(idToken),
						chatService.getGlobalChats(idToken),
					]);

					// Update all states
					if (userInfo?.aiType) setAiType(userInfo.aiType);
					setTokenCount(tokens);
					setFolders(folders);
					setChatsOutsideFolders(chats);
				} catch (error) {
					console.error("Error fetching initial data:", error);
				} finally {
					setIsInitialLoading(false);
				}
			} else {
				console.log("User not authenticated");
			}
		});

		return () => unsubscribe();
	}, []);

	const fetchFolders = async () => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		try {
			const idToken = await auth.currentUser.getIdToken();
			const folders = await folderService.getFolders(idToken);
			setFolders(folders);
		} catch (error) {
			console.error("Error fetching folders:", error);
		}
	};

	const fetchChatsOutsideFolders = async () => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		try {
			const idToken = await auth.currentUser.getIdToken();
			const chats = await chatService.getGlobalChats(idToken);
			setChatsOutsideFolders(chats);
		} catch (error) {
			console.error("Error fetching chats outside folders:", error);
		}
	};

	// Fetch folder-specific chats
	const updateFolderChats = (
		folders: Folder[],
		folderId: string,
		chats: Chat[],
	): Folder[] => {
		return folders.map((folder) => {
			if (folder.id === folderId) {
				// Update the chats for the matching folder
				return { ...folder, chats };
			}
			if (folder.subFolders && folder.subFolders.length > 0) {
				// Recursively update subfolders
				return {
					...folder,
					subFolders: updateFolderChats(folder.subFolders, folderId, chats),
				};
			}
			return folder;
		});
	};

	const fetchFolderChats = async (folderId: string) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		try {
			const idToken = await auth.currentUser.getIdToken();
			const chats = await folderService.getFolderChats(folderId, idToken);
			//console.log(`Received chats for folder ${folderId}:`, chats);

			// Update the folders state with a new reference to trigger re-render
			setFolders((prevFolders) => {
				const updateFolderWithChats = (folders: Folder[]): Folder[] => {
					return folders.map((folder) => {
						if (folder.id === folderId) {
							return {
								...folder,
								chats: [...chats], // Create new array reference
							};
						}
						if (folder.subFolders?.length) {
							return {
								...folder,
								subFolders: updateFolderWithChats(folder.subFolders),
							};
						}
						return folder;
					});
				};

				return updateFolderWithChats([...prevFolders]); // Create new array reference
			});

			// Also update folderChats state if you're using it
			setFolderChats((prev) => ({
				...prev,
				[folderId]: chats,
			}));
		} catch (error) {
			console.error("Error fetching folder chats:", error);
		}
	};

	const handleAddFolder = async () => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();
			await folderService.createFolder(newFolderName, idToken);
			fetchFolders(); // Refresh folder list
			setNewFolderName(""); // Clear input after adding
		} catch (error) {
			console.error("Error creating folder:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAddChat = async (folderId: string | null = null) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();
			const newChatId = await chatService.createChat("Chat", idToken, folderId);

			// Set the new chat as active
			setActiveChat(newChatId);

			// If the chat is being added to a folder, ensure that folder is expanded
			if (folderId) {
				// Add to expanded folders if not already expanded
				if (!expandedFolders.includes(folderId)) {
					setExpandedFolders([...expandedFolders, folderId]);
				}

				// Immediately fetch the updated folder chats
				await fetchFolderChats(folderId);
			} else {
				await fetchChatsOutsideFolders();
			}
		} catch (error) {
			console.error("Error creating chat:", error);
		} finally {
			setIsLoading(false);
			if (isMobileState) setIsOpen(false);
		}
	};

	const handleDeleteSelectedChats = async () => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();

			const affectedFolderIds: string[] = [];

			// Get folder IDs directly from the selected chats
			selectedChats.forEach((chatId) => {
				// First check chats outside folders
				const globalChat = chatsOutsideFolders.find((c) => c.id === chatId);

				// Then check all folder chats
				let folderChat;
				Object.entries(folderChats).forEach(([folderId, chats]) => {
					const found = chats?.find((c) => c.id === chatId);
					if (found) folderChat = found;
				});

				const chat = globalChat || folderChat;

				if (chat?.folderId && !affectedFolderIds.includes(chat.folderId)) {
					affectedFolderIds.push(chat.folderId);
				}
			});

			// Delete the chats
			await chatService.deleteMultipleChats(selectedChats, idToken);

			// Refresh affected folders
			for (let i = 0; i < affectedFolderIds.length; i++) {
				await fetchFolderChats(affectedFolderIds[i]);
			}

			// Refresh global chats
			await fetchChatsOutsideFolders();
		} catch (error) {
			console.error("Error deleting chats:", error);
		} finally {
			setIsLoading(false);
			setSelectedChats([]);
			setManageHistory(false);
		}
	};

	// Delete chat function for both global and folder-specific chats
	const handleDeleteChat = async (
		chatId: string,
		folderId: string | null = null,
	) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();
			await chatService.deleteChat(chatId, idToken);
			// Refresh the chat lists based on whether it is in a folder or not
			if (folderId) {
				fetchFolderChats(folderId); // Refresh the folder's chats if it's in a folder
			} else {
				fetchChatsOutsideFolders(); // Refresh global chats
			}
		} catch (error) {
			console.error("Error deleting chat:", error);
		} finally {
			setIsLoading(false);
		}
	};

	// Rename chat function
	const handleRenameChat = async (
		chatId: string,
		newName: string,
		folderId: string | null = null,
	) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			// Optimistically update the UI first
			if (folderId) {
				setFolders((prevFolders) =>
					updateFolderChats(
						prevFolders,
						folderId,
						folders
							?.find((f) => f.id === folderId)
							?.chats?.map((chat) =>
								chat.id === chatId ? { ...chat, name: newName } : chat,
							) || [],
					),
				);
			} else {
				setChatsOutsideFolders((prevChats) =>
					prevChats.map((chat) =>
						chat.id === chatId ? { ...chat, name: newName } : chat,
					),
				);
			}

			// Then make the API call
			const idToken = await auth.currentUser.getIdToken();
			await chatService.renameChat(chatId, newName, idToken);

			// Still fetch the latest data from server to ensure consistency
			if (folderId) {
				//console.log(`Fetching folder chats after renaming chat ${chatId} to ${newName} in folder ${folderId}`);
				fetchFolderChats(folderId);
			} else {
				fetchChatsOutsideFolders();
			}
		} catch (error) {
			console.error("Error renaming chat:", error);
			// Revert the optimistic update on error by re-fetching
			if (folderId) {
				fetchFolderChats(folderId);
			} else {
				fetchChatsOutsideFolders();
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeleteFolder = async (folderId: string) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();
			await folderService.deleteFolder(folderId, idToken);
			// Refresh the folder list after deletion
			fetchFolders();
		} catch (error) {
			console.error("Error deleting folder:", error);
		} finally {
			setIsLoading(false);
		}
	};
	const [{ isOver }, dropOutsideFolder] = useDrop({
		accept: "CHAT",
		drop: async (item: any) => {
			// Skip if chat is already outside folders (global)
			if (!item.sourceFolderId) {
				return;
			}

			const updatedChat = { ...item.chat, folderId: null };

			// Optimistically update UI
			if (item.sourceFolderId) {
				// Remove from source folder's folderChats
				setFolderChats((prev) => ({
					...prev,
					[item.sourceFolderId]: (prev[item.sourceFolderId] || []).filter(
						(chat) => chat.id !== item.chatId,
					),
				}));

				// Remove from source folder
				setFolders((prevFolders) => {
					const removeFromSource = (folders: Folder[]): Folder[] => {
						return folders.map((f) => {
							if (f.id === item.sourceFolderId) {
								return {
									...f,
									chats: (f.chats || []).filter(
										(chat) => chat.id !== item.chatId,
									),
								};
							}
							if (f.subFolders?.length) {
								return {
									...f,
									subFolders: removeFromSource(f.subFolders),
								};
							}
							return f;
						});
					};
					return removeFromSource(prevFolders);
				});

				// Add to chats outside folders
				setChatsOutsideFolders((prev) => [...prev, updatedChat]);

				try {
					await chatService.updateChatFolder(item.chatId, null);
				} catch (error) {
					console.error("Failed to move chat to global section:", error);
					// Revert optimistic updates
					if (item.sourceFolderId) {
						await fetchFolderChats(item.sourceFolderId);
					}
					await fetchChatsOutsideFolders();
				}
			}
		},
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	});
	const useFolderDragStatus = () => {
		const { itemType, isDragging } = useDragLayer((monitor) => ({
			itemType: monitor.getItemType(),
			isDragging: monitor.isDragging(),
		}));
		return isDragging && itemType === "FOLDER"; // Returns true only if a folder is being dragged
	};
	const isDraggingFolder = useFolderDragStatus();
	const [{ isOverDropSpot }, dropFoldersSection] = useDrop({
		accept: "FOLDER",
		drop: async (item: any) => {
			// Optimistically update UI first
			setFolders((prevFolders) => {
				// Find and remove the folder from its current location
				const findAndRemoveFolder = (
					folders: Folder[],
				): [Folder[], Folder | null] => {
					let removedFolder: Folder | null = null;
					const updatedFolders = folders.filter((folder) => {
						if (folder.id === item.folderId) {
							removedFolder = { ...folder, parentFolderId: undefined };
							return false;
						}
						if (folder.subFolders?.length) {
							const [newSubFolders, found] = findAndRemoveFolder(
								folder.subFolders,
							);
							folder.subFolders = newSubFolders;
							if (found) removedFolder = found;
						}
						return true;
					});
					return [updatedFolders, removedFolder];
				};

				const [foldersWithoutSource, movedFolder] =
					findAndRemoveFolder(prevFolders);
				if (!movedFolder) return prevFolders;

				// Add to root level
				return [...foldersWithoutSource, movedFolder];
			});

			// Update subFolders state if the folder was in a parent folder
			if (item.parentId) {
				setSubFolders((prev) => {
					const newSubFolders = { ...prev };
					if (newSubFolders[item.parentId]) {
						newSubFolders[item.parentId] = newSubFolders[item.parentId].filter(
							(f) => f.id !== item.folderId,
						);
						// Remove the key if the array is empty
						if (newSubFolders[item.parentId].length === 0) {
							delete newSubFolders[item.parentId];
						}
					}
					return newSubFolders;
				});
			}

			try {
				await folderService.updateFolderParent(item.folderId, null);
			} catch (error) {
				console.error("Failed to move folder to root level:", error);
				fetchFolders(); // Revert on error
			}
		},
		collect: (monitor) => ({
			isOverDropSpot: !!monitor.isOver(),
		}),
	});
	// Rename folder function
	const handleUpdateFolder = async (
		folderId: string,
		newName: string,
		emoji: string,
		color: string,
	) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			// Update folders recursively, including subfolders
			setFolders((prevFolders) => {
				const updateFolderRecursively = (folders: Folder[]): Folder[] => {
					return folders.map((folder) => {
						if (folder.id === folderId) {
							return { ...folder, name: newName, emoji, color };
						}
						if (folder.subFolders?.length) {
							return {
								...folder,
								subFolders: updateFolderRecursively(folder.subFolders),
							};
						}
						return folder;
					});
				};
				return updateFolderRecursively(prevFolders);
			});

			// Also update the subFolders state if needed
			setSubFolders((prev) => {
				const newSubFolders = { ...prev };
				Object.keys(newSubFolders).forEach((parentId) => {
					newSubFolders[parentId] = newSubFolders[parentId].map((folder) =>
						folder.id === folderId
							? { ...folder, name: newName, emoji, color }
							: folder,
					);
				});
				return newSubFolders;
			});

			// Make the API call
			const idToken = await auth.currentUser.getIdToken();
			await folderService.renameFolder(
				folderId,
				newName,
				emoji,
				color,
				idToken,
			);
		} catch (error) {
			console.error("Error renaming folder:", error);
			fetchFolders();
		} finally {
			setIsLoading(false);
		}
	};

	// Expand/collapse folder and fetch chats when expanding
	const toggleFolder = (folderId: string) => {
		console.log(`Toggling folder: ${folderId}`); // Debug log
		if (expandedFolders.includes(folderId)) {
			setExpandedFolders(expandedFolders.filter((id) => id !== folderId));
		} else {
			setExpandedFolders([...expandedFolders, folderId]);
			// Always fetch chats when expanding any folder, regardless of nesting level
			fetchFolderChats(folderId);
		}
	};

	// const updateAiType = async (selectedAi: "Gemini" | "GPT 4o") => {
	// 	try {
	// 		setIsLoading(true); // Set loading state
	// 		await userService.updateAiType(auth.currentUser!.uid, selectedAi); // Update AI type via service
	// 		setAiType(selectedAi); // Update local state after successful update
	// 	} catch (error) {
	// 		console.error("Failed to update AI type:", error);
	// 	} finally {
	// 		setIsLoading(false); // Reset loading state
	// 	}
	// };

	const handleChangeFolderColor = async (
		folderId: string,
		name: string,
		emoji: string,
		newColor: string,
	) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		try {
			// Update folders recursively, including subfolders
			setFolders((prevFolders) => {
				const updateFolderRecursively = (folders: Folder[]): Folder[] => {
					return folders.map((folder) => {
						if (folder.id === folderId) {
							return { ...folder, color: newColor };
						}
						if (folder.subFolders?.length) {
							return {
								...folder,
								subFolders: updateFolderRecursively(folder.subFolders),
							};
						}
						return folder;
					});
				};
				return updateFolderRecursively(prevFolders);
			});

			// Also update the subFolders state
			setSubFolders((prev) => {
				const newSubFolders = { ...prev };
				Object.keys(newSubFolders).forEach((parentId) => {
					newSubFolders[parentId] = newSubFolders[parentId].map((folder) =>
						folder.id === folderId ? { ...folder, color: newColor } : folder,
					);
				});
				return newSubFolders;
			});

			const idToken = await auth.currentUser.getIdToken();
			await folderService.renameFolder(
				folderId,
				name,
				emoji,
				newColor,
				idToken,
			);
		} catch (error) {
			console.error("Error changing folder color:", error);
			fetchFolders(); // Revert on error
		}
	};

	const handleChangeEmoji = async (
		folderId: string,
		name: string,
		newEmoji: string,
		color: string,
	) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		try {
			// Update folders recursively, including subfolders
			setFolders((prevFolders) => {
				const updateFolderRecursively = (folders: Folder[]): Folder[] => {
					return folders.map((folder) => {
						if (folder.id === folderId) {
							return { ...folder, emoji: newEmoji };
						}
						if (folder.subFolders?.length) {
							return {
								...folder,
								subFolders: updateFolderRecursively(folder.subFolders),
							};
						}
						return folder;
					});
				};
				return updateFolderRecursively(prevFolders);
			});

			// Also update the subFolders state
			setSubFolders((prev) => {
				const newSubFolders = { ...prev };
				Object.keys(newSubFolders).forEach((parentId) => {
					newSubFolders[parentId] = newSubFolders[parentId].map((folder) =>
						folder.id === folderId ? { ...folder, emoji: newEmoji } : folder,
					);
				});
				return newSubFolders;
			});

			const idToken = await auth.currentUser.getIdToken();
			await folderService.renameFolder(
				folderId,
				name,
				newEmoji,
				color,
				idToken,
			);
		} catch (error) {
			console.error("Error changing folder emoji:", error);
			fetchFolders(); // Revert on error
		}
	};

	// Filter folders based on debouncedSearchTerm

	const handleMoveFolderToFolder = async (
		sourceFolderId: string,
		targetFolderId: string,
	) => {
		// Optimistically update UI
		setFolders((prevFolders) => {
			// Helper function to find and remove a folder from any level
			const findAndRemoveFolder = (
				folders: Folder[],
			): [Folder[], Folder | null] => {
				let removedFolder: Folder | null = null;
				const updatedFolders = folders.filter((folder) => {
					if (folder.id === sourceFolderId) {
						removedFolder = folder;
						return false;
					}
					if (folder.subFolders?.length) {
						const [newSubFolders, found] = findAndRemoveFolder(
							folder.subFolders,
						);
						folder.subFolders = newSubFolders;
						if (found) removedFolder = found;
					}
					return true;
				});
				return [updatedFolders, removedFolder];
			};

			// Helper function to add folder to target
			const addToTarget = (
				folders: Folder[],
				folderToAdd: Folder,
			): Folder[] => {
				return folders.map((folder) => {
					if (folder.id === targetFolderId) {
						return {
							...folder,
							subFolders: [
								...(folder.subFolders || []),
								{ ...folderToAdd, parentId: targetFolderId },
							],
						};
					}
					if (folder.subFolders?.length) {
						return {
							...folder,
							subFolders: addToTarget(folder.subFolders, folderToAdd),
						};
					}
					return folder;
				});
			};

			// First find and remove the folder from its current location
			const [foldersWithoutSource, movedFolder] =
				findAndRemoveFolder(prevFolders);

			if (!movedFolder) {
				console.error("Source folder not found");
				return prevFolders;
			}

			// Then add it to the target folder
			return addToTarget(foldersWithoutSource, movedFolder);
		});

		// Make API call
		try {
			const idToken = await auth.currentUser?.getIdToken();
			if (idToken) {
				await folderService.updateFolderParent(sourceFolderId, targetFolderId);
			}
		} catch (error) {
			console.error("Failed to move folder:", error);
			// Revert on error by fetching fresh data
			fetchFolders();
		}
	};

	return (
		<div
			{...(isMobileState ? handlers : {})}
			className={`h-screen flex flex-col bg-gray-800 dark:bg-gray-800 transition-all duration-300 border-r border-gray-500 ${
				isOpen ? "w-full" : "w-[60px]"
			}`}
		>
			<div
				className={`flex ${
					isOpen ? "flex-col items-start" : "justify-center"
				} w-full p-4 gap-2`}
			>
				<div className="pb-3 flex flex-row justify-between w-full items-center">
					{isOpen ? (
						<>
							<div>
								<img src={logo} alt="Folderly" className="w-28" />
							</div>
							<div className="flex flex-row gap-3  items-center">
								<Tooltip text="New Chat">
									<AddChatButton onAddChat={handleAddChat} />
								</Tooltip>
								<Tooltip text="New Folder">
									<AddFolderButton onAddFolder={handleAddFolder} />
								</Tooltip>
								<Tooltip text="Toggle Sidebar">
									<ToggleSidebarButton
										isOpen={isOpen}
										onToggle={toggleSidebar}
									/>
								</Tooltip>
							</div>
						</>
					) : (
						<div className="flex flex-col gap-6 items-center">
							<img src={logoFolder} alt="Folderly" className="w-28" />
							<ToggleSidebarButton isOpen={isOpen} onToggle={toggleSidebar} />
							<AddChatButton onAddChat={handleAddChat} />
							<AddFolderButton onAddFolder={handleAddFolder} />
						</div>
					)}
				</div>

				{isOpen && (
					<div className="text-sm text-gray-500">Chats: {tokenCount}</div>
				)}
			</div>

			{isOpen && (
				<div className="flex-1 flex flex-col overflow-hidden">
					{/* AI Toggle and Search */}
					<div className="mt-2 px-4 space-y-4">
						{/* <div className="ai-toggle">
							<select
								id="aiToggle"
								value={aiType}
								onChange={(e) =>
									updateAiType(e.target.value as "Gemini" | "GPT 4o")
								}
								className="w-full px-3 py-2 outline outline-white outline-1 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white border-r-8 border-transparent"
							>
								<option value="GPT 4o">GPT-4o</option>
								<option value="Gemini">Gemini Pro</option>
							</select>
						</div> */}
						<div
							onClick={(e) => e.stopPropagation()}
							className="search-chats relative"
						>
							<input
								type="text"
								value={searchTerm}
								onChange={handleSearchChange}
								placeholder="Search chats..."
								className="w-full px-3 py-2 outline outline-gray-600 outline-1 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
							/>
							{isSearching && (
								<div className="absolute right-3 top-1/2 transform -translate-y-1/2">
									<div className="animate-spin rounded-full h-4 w-4 border-b-2 border-purple-500"></div>
								</div>
							)}
						</div>

						{manageHistory ? (
							<div className="p-2 rounded-md flex flex-row items-center justify-between">
								{/* <div>
										<input type="checkbox" className="pe-2 me-2" /> Select All
									</div> */}
								<button
									onClick={handleDeleteSelectedChats}
									className="flex flex-row items-center gap-2"
								>
									<Trash2 className="h-4 w-4" />
									Delete Selected
								</button>
								<button
									onClick={handleSetManageHistory}
									className="flex flex-row items-center gap-2"
								>
									<FaTimes />
								</button>
							</div>
						) : (
							<div className="p-2 rounded-md flex flex-row items-center justify-between">
								{/* <div>
										<input type="checkbox" className="pe-2 me-2" /> Select All
									</div> */}
								<button
									onClick={handleSetManageHistory}
									className="flex flex-row items-center gap-2"
								>
									<FaClock className="h-4 w-4" />
									Manage history
								</button>
							</div>
						)}
					</div>

					{/* Add loading state for initial data fetch */}
					{isInitialLoading ? (
						<div className="flex-1 flex items-center justify-center">
							<div className="flex flex-col items-center space-y-4">
								<div className="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
								<p className="text-gray-500 dark:text-gray-400">
									Loading your chats...
								</p>
							</div>
						</div>
					) : (
						<div className="flex-1 overflow-y-auto mt-4 px-0">
							{/* Chat and Folder List */}
							<div className="flex-1 overflow-y-auto mt-4 px-4">
								{searchTerm.length >= 3 ? (
									<div className="mt-4">
										{isSearching ? (
											<div className="flex flex-col items-center space-y-2 text-gray-500">
												<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
												<p>Searching through chats and messages...</p>
											</div>
										) : matchingChats.length > 0 ? (
											matchingChats.map((chat: Chat) => (
												<ChatItem
													key={chat.id}
													chat={chat}
													setChatsOutsideFolders={setChatsOutsideFolders}
													fetchFolderChats={fetchFolderChats}
													selectedChats={selectedChats}
													setSelectedChats={setSelectedChats}
												/>
											))
										) : (
											<p className="text-gray-500">No matching chats found.</p>
										)}
									</div>
								) : (
									<>
										{/* Folders section */}
										<div>
											{folders.length > 0 ? (
												folders.map((folder: Folder) => (
													<FolderItem
														key={folder.id}
														folder={folder}
														setFolders={setFolders}
														isExpanded={expandedFolders.includes(folder.id)}
														isLoading={isLoading}
														onToggle={toggleFolder}
														onUpdate={handleUpdateFolder}
														onDelete={handleDeleteFolder}
														onAddChat={handleAddChat}
														onRenameChat={handleRenameChat}
														onDeleteChat={handleDeleteChat}
														highlight={searchTerm}
														onChangeEmoji={handleChangeEmoji}
														onChangeColor={handleChangeFolderColor}
														expandedFolders={expandedFolders || []}
														fetchFolderChats={fetchFolderChats}
														fetchChatsOutsideFolders={fetchChatsOutsideFolders}
														onMoveFolderToFolder={handleMoveFolderToFolder}
														selectedChats={selectedChats}
														setSelectedChats={setSelectedChats}
													/>
												))
											) : (
												<p className="text-gray-500"></p>
											)}
											{isDraggingFolder && (
												<div
													ref={dropFoldersSection}
													className={`mt-4 p-4 rounded-md border-dashed border-2 border-gray-400 transition-colors duration-200 ${
														isOverDropSpot
															? "bg-purple-200 dark:bg-purple-800"
															: ""
													}`}
												>
													Drop here to move to root level
												</div>
											)}
											{/* Global chats section */}
											<div
												ref={dropOutsideFolder}
												className={`mb-4 p-2 rounded-md transition-colors duration-200 ${
													isOver ? "bg-purple-200 dark:bg-purple-800" : ""
												}`}
											>
												{chatsOutsideFolders &&
												chatsOutsideFolders.length > 0 ? (
													chatsOutsideFolders.map((chat: Chat) => (
														<ChatItem
															key={chat.id}
															chat={chat}
															setChatsOutsideFolders={setChatsOutsideFolders}
															fetchFolderChats={fetchFolderChats}
															selectedChats={selectedChats}
															setSelectedChats={setSelectedChats}
														/>
													))
												) : (
													<p className="text-gray-500 italic"></p>
												)}
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					)}

					{/* Subscription management button 
					<div className="account-controls w-full flex justify-evenly p-4 mt-auto">
						<div className="relative group">
							<button
								onClick={handleSignOut}
								className="text-white py-2 px-2 rounded bg-purple-600 hover:bg-[#3D17DD] transition-colors"
							>
								<SignOutSVG />
							</button>
							<div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
								Sign Out
							</div>
						</div>
						<a
							href="/account"
							className="text-white py-2 px-4 rounded bg-purple-600 hover:bg-[#3D17DD] transition-colors"
						>
							Manage Account
						</a>
						<a
							href="/clips"
							className="text-white py-2 px-4 rounded bg-purple-600 hover:bg-[#3D17DD] transition-colors"
						>
							Clips
						</a>
					</div>
						*/}
				</div>
			)}

			{/* <button onClick={fetchFolders}>TOTO</button> */}
			<Joyride
				steps={steps}
				run={runTour}
				continuous={true}
				showSkipButton={true}
				showProgress={true}
				styles={{
					options: {
						primaryColor: "#9333ea",
						zIndex: 1000,
					},
				}}
				callback={(data) => {
					const { status } = data;
					if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
						setRunTour(false);
					}
				}}
			/>
			{isOpen && (
				<button
					onClick={() => setRunTour(true)}
					className="text-sm text-white w-full p-4 flex flex-row items-center justify-center"
				>
					<MdOutlineTipsAndUpdates className="w-6 h-6" />
					<span className="ml-2">Show Tutorial</span>
				</button>
			)}
		</div>
	);
};

export default Sidebar;
