import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, sendPasswordResetEmailFunc } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import { features } from "../data/content";
import Header from "../components/general/public-header";
import { Social } from "../components/auth/social";
import { Link } from "../components/auth/link";

import { getAuth, onAuthStateChanged, User } from "firebase/auth";

const SignInPage = () => {
	const [email, setEmail] = useState("");
	const [isResetPassword, setIsResetPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [error, setError] = useState<string | null>(null);
	const [user, setUser] = useState<User | null>(null);

	const navigate = useNavigate(); // Initialize useNavigate

	const buttonLabel = "Sign In with Google";

	const handleResetPasswordstate = () => {
		setIsResetPassword(!isResetPassword);
	};

	// Listen for authentication state changes
	useEffect(() => {
		const auth = getAuth();

		const unsubscribe = onAuthStateChanged(auth, (authUser) => {
			if (authUser) {
				console.log("User is signed in:", authUser);
				setUser(authUser); // Update the user state
			} else {
				console.log("No user is signed in.");
				setUser(null);
			}
		});

		return () => unsubscribe(); // Cleanup the listener
	}, []);

	const handleSignIn = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const userCredential = await signInWithEmailAndPassword(
				auth,
				email,
				password,
			);
			console.log("User signed in:", userCredential.user);
			// Redirect to /chat upon successful sign-in
			navigate("/chat");
		} catch (error) {
			setError((error as Error).message);
			console.error("Sign-in error:", error);
		}
	};

	//send reset password email
	const handleResetPassword = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await sendPasswordResetEmailFunc(auth, email);
			handleResetPasswordstate();
			alert("Password reset email sent!");
		} catch (error) {
			console.error("Error sending password reset email:", error);
		}
	};

	return (
		<div className="w-full min-h-screen flex-col items-center justify-center bg-gray-800">
			<Header user={user} setUser={setUser} />
			<div className="flex flex-col-reverse md:flex-row gap-8 w-full justify-center items-center">
				{/* Benefits Section */}

				{/* Sign In Form */}
				<div className="bg-gray-700/50 rounded-2xl p-8 md:p-12 space-y-4 xl:w-1/4">
					<h2 className="text-2xl md:text-3xl font-bold text-center mb-8 text-white">
						{isResetPassword ? "Reset Password" : "Sign In"}
					</h2>
					{error && (
						<div className="bg-red-500/10 border border-red-500/50 text-red-200 px-4 py-3 rounded-lg mb-6">
							{error}
						</div>
					)}

					{isResetPassword ? (
						<>
							<form
								onSubmit={handleResetPassword}
								className="flex flex-col space-y-5"
							>
								<input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Email"
									required
									className="px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent text-white placeholder-gray-400"
								/>

								<button
									type="submit"
									className="w-full bg-primary text-white py-3 rounded-lg hover:bg-primary/80 transition duration-300 font-medium"
								>
									Submit
								</button>
							</form>
							<button
								onClick={handleResetPasswordstate}
								className="text-purple-600 hover:text-purple-300 transition duration-300"
							>
								Back to Sign In
							</button>
						</>
					) : (
						<>
							<Social
								currentAction="signin"
								buttonLabel={buttonLabel || "Default Label"}
							/>
							<form onSubmit={handleSignIn} className="flex flex-col space-y-5">
								<input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Email"
									required
									className="px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent text-white placeholder-gray-400"
								/>
								<input
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									placeholder="Password"
									required
									className="px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent text-white placeholder-gray-400"
								/>
								<button
									type="submit"
									className="w-full bg-primary text-white py-3 rounded-lg hover:bg-primary/80 transition duration-300 font-medium"
								>
									Sign In
								</button>
							</form>
							{/* <Link
								accountLabel="Not rgistered yet?"
								linkLabel="Sign Up"
								linkPage="signup"
							/> */}
							<button
								onClick={handleResetPasswordstate}
								className="text-primary hover:text-primary/80 transition duration-300"
							>
								Forgot password?
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default SignInPage;
