import React, { useEffect, useState } from "react";
import { auth } from "../../firebase/config";
import { chatService } from "../../services/chat";
import { useChatContext } from "../../context/chatContext";
import { Pencil, Trash2 } from "lucide-react";
import { useDrag } from "react-dnd";
import { Chat } from "../../types";

interface ChatItemProps {
	parentFolderId?: string | null;
	chat: Chat;
	fetchFolderChats?: (folderId: string) => void;
	setChatsOutsideFolders?: (chats: Chat[]) => void;
	isClip?: boolean;
	selectedChats: string[];
    setSelectedChats: (value: React.SetStateAction<string[]>) => void;
}

const ChatItem: React.FC<ChatItemProps> = ({
	parentFolderId,
	chat,
	fetchFolderChats,
	setChatsOutsideFolders,
	isClip,
	selectedChats, 
	setSelectedChats
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [renamingChat, setRenamingChat] = useState<string | null>(null);
	const [newChatName, setNewChatName] = useState<string>("");


	const { activeChat, setActiveChat, manageHistory } = useChatContext();
	const [{ isDragging }, drag] = useDrag({
		type: "CHAT",
		item: {
			type: "CHAT",
			chatId: chat.id,
			chat: chat,
			sourceFolderId: chat.folderId,
		},
		canDrag: !renamingChat,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	const fetchChatsOutsideFolders = async () => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		try {
			const idToken = await auth.currentUser.getIdToken();
			const chats = await chatService.getGlobalChats(idToken);
			setChatsOutsideFolders?.(chats);
		} catch (error) {
			console.error("Error fetching chats outside folders:", error);
		}
	};

	// Delete chat function for both global and folder-specific chats
	const handleDeleteChat = async (
		chatId: string,
		folderId: string | null = null,
	) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();
			await chatService.deleteChat(chatId, idToken);

			// Refresh the appropriate chat list
			if (folderId && fetchFolderChats) {
				await fetchFolderChats(folderId);
			} else {
				await fetchChatsOutsideFolders();
			}
		} catch (error) {
			console.error("Error deleting chat:", error);
		} finally {
			setIsLoading(false);
		}
	};

	// Rename chat function
	const handleRenameChat = async (
		chatId: string,
		newName: string,
		folderId: string | null = null,
	) => {
		if (!auth.currentUser) {
			console.error("User not authenticated");
			return;
		}

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();
			await chatService.renameChat(chatId, newName, idToken);
			// Refresh chats in folder or global chats after renaming

			//console.log("folderId", folderId);
			//console.log("fetchFolderChats", fetchFolderChats);
			if (folderId && fetchFolderChats) {
				fetchFolderChats(folderId);
			} else {
				fetchChatsOutsideFolders();
			}
		} catch (error) {
			console.error("Error renaming chat:", error);
		} finally {
			setIsLoading(false);
		}
	};

	//Add the selected chat to the selectedChats array
	const handleCheckboxChange = (chatId:string) => {
		if (!selectedChats.includes(chatId)) {
			setSelectedChats([...selectedChats, chatId]);
		} else {
			setSelectedChats(selectedChats.filter(id => id !== chatId));
		}
	};

	useEffect(() => {
		const handleClickOutsideRename = (event: MouseEvent) => {
			// Cast event.target to HTMLElement to access classList
			const target = event.target as HTMLElement;

			// Check if the click is outside the input field and buttons
			const isClickInInput = target.tagName.toLowerCase() === "input";
			const isClickInButton = target.tagName.toLowerCase() === "button";

			if (
				renamingChat === chat.id &&
				newChatName.trim() &&
				!isClickInInput &&
				!isClickInButton
			) {
				setRenamingChat(null);
				handleRenameChat(chat.id, newChatName, null);
			}
		};

		if (renamingChat === chat.id) {
			document.addEventListener("mousedown", handleClickOutsideRename);
		} else {
			document.removeEventListener("mousedown", handleClickOutsideRename);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideRename);
		};
	}, [renamingChat, newChatName]);
	return (
		<div
			ref={drag}
			className={`chat-item ${isDragging ? "opacity-50" : ""} cursor-move`}
		>
			<div
				key={chat.id}
				className="p-2 mb-2 rounded bg-gray-300 dark:bg-gray-700 flex justify-between"
			>
				{renamingChat === chat.id ? (
					<div className="flex flex-col w-full">
						<input
							type="text"
							value={newChatName}
							onChange={(e) => setNewChatName(e.target.value)}
							className="border px-2 py-1 w-full bg-gray-100 dark:bg-gray-600 dark:text-white"
							onClick={(e) => e.stopPropagation()}
							onKeyDown={(e) => {
								if (e.key === "Enter" && newChatName.trim()) {
									handleRenameChat(chat.id, newChatName, parentFolderId);
									setRenamingChat(null);
								}
							}}
							autoFocus
						/>
						<div className="flex space-x-2 mt-2">
							<button
								onClick={(e) => {
									e.stopPropagation();
									handleRenameChat(chat.id, newChatName, parentFolderId);
									setRenamingChat(null);
								}}
								className="text-blue-500"
								disabled={isLoading || !newChatName.trim()}
							>
								Save
							</button>
							<button
								onClick={(e) => {
									e.stopPropagation();
									setRenamingChat(null);
									setNewChatName(chat.name); // Reset to original name
								}}
								className="text-gray-500"
							>
								Cancel
							</button>
						</div>
					</div>
				) : (
					<div
						onClick={() => setActiveChat(chat.id)}
						className={`cursor-pointer w-full p-2 rounded-md flex justify-between ${
							activeChat === chat.id ? "bg-gray-600" : ""
						}`}
					>	
						{/* Checkbox */}
						<div className="flex fle-row gap-2">
						{ manageHistory && (
							
							<input 
								type="checkbox" 
								className="opacity-15 hover:opacity-100 transition-opacity duration-300 checked:opacity-100"
								onClick={(e) => e.stopPropagation()} // Prevent parent click
								onChange={() => handleCheckboxChange(chat.id)} // Update selection
								checked={selectedChats.includes(chat.id)}
							/>
							
						)
						}
						<p className={ activeChat === chat.id ? "text-lime-500":"text-white"}>{chat.name}</p>
						</div>
						
						<div className="flex space-x-2 transition-opacity duration-300">
							<button
								onClick={(e) => {
									e.stopPropagation();
									setRenamingChat(chat.id);
									setNewChatName(chat.name); // Pre-fill with current name
								}}
								className="text-blue-500"
							>
								<Pencil className="h-4 w-4" />
							</button>
							<button
								onClick={(e) => {
									e.stopPropagation();
									handleDeleteChat(chat.id, chat.folderId || null);
								}}
								className="text-red-500"
							>
								<Trash2 className="h-4 w-4" />
							</button>
						</div>
					</div>

					// <div
					// 	onClick={() => setActiveChat(chat.id)}
					// 	className={`cursor-pointer w-full p-2 rounded-md flex justify-between ${
					// 		activeChat === chat.id ? "bg-gray-600" : ""
					// 	}`}
					// >
					// 	<p className="dark:text-white">{chat.name}</p>
					// 	<div className="flex space-x-2">
					// 		<button
					// 			onClick={(e) => {
					// 				e.stopPropagation();
					// 				setRenamingChat(chat.id);
					// 				setNewChatName(chat.name); // Pre-fill with current name
					// 			}}
					// 			className="text-blue-500"
					// 		>
					// 			<Pencil className="h-4 w-4" />
					// 		</button>
					// 		{/* Delete chat button */}
					// 		<button
					// 			onClick={(e) => {
					// 				e.stopPropagation();
					// 				handleDeleteChat(chat.id, chat.folderId || null);
					// 			}}
					// 			className="text-red-500"
					// 		>
					// 			<Trash2 className="h-4 w-4" />
					// 		</button>
					// 	</div>
					// </div>
				)}
			</div>
		</div>
	);
};

export default ChatItem;
