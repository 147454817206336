import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "../../firebase/config";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { userService } from "../../services/user";
import ProfilePicture from "../profile/profile-picture";
import {
	FaChevronDown,
	FaCheckCircle,
	FaClipboard,
	FaSignOutAlt,
	FaUser,
} from "react-icons/fa";
import { HiSupport } from "react-icons/hi";
import { MdSubscriptions } from "react-icons/md";
import { IoExtensionPuzzle, IoSettingsOutline } from "react-icons/io5";

interface TopbarProps {
	openModal: () => void;
}

const Topbar: React.FC<TopbarProps> = ({ openModal }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const [isOptionsOpen, setIsOptionsOpen] = useState(false);
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const settingsRef = useRef<HTMLDivElement>(null);
	const optionsRef = useRef<HTMLDivElement>(null);

	const navigate = useNavigate();

	const [aiType, setAiType] = useState<
		"Gemini" | "GPT 4o" | "o1-preview" | "claude-3"
	>("GPT 4o");

	const [options, setOptions] = useState<
		"Options" | "Personas" | "Prompt Library" | "Training Videos"
	>("Options");

	const handleShowMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const handleShowSelect = () => {
		setIsSelectOpen(!isSelectOpen);
		setIsOptionsOpen(false);
	};

	const handleShowOptions = () => {
		setIsOptionsOpen(!isOptionsOpen);
		setIsSelectOpen(false);
	};

	const handleShowSettings = () => {
		setIsSettingsOpen(!isSettingsOpen);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			setIsMenuOpen(false);
		}
		if (
			settingsRef.current &&
			!settingsRef.current.contains(event.target as Node)
		) {
			setIsSettingsOpen(false);
		}
		if (
			optionsRef.current &&
			!optionsRef.current.contains(event.target as Node)
		) {
			setIsOptionsOpen(false);
		}
	};

	const handleSignOut = async () => {
		const auth = getAuth();
		try {
			await signOut(auth);
			console.log("User signed out successfully");
			navigate("/");
		} catch (error) {
			console.error("Error signing out: ", error);
		}
	};

	const updateAiType = async (
		selectedAi: "Gemini" | "GPT 4o" | "o1-preview" | "claude-3",
	) => {
		try {
			setIsLoading(true);
			await userService.updateAiType(auth.currentUser!.uid, selectedAi);
			setAiType(selectedAi);
		} catch (error) {
			console.error("Failed to update AI type:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const fetchAiType = async () => {
			try {
				const idToken = await auth.currentUser!.getIdToken();
				const userInfo = await userService.getUserInfo(idToken);
				if (userInfo.aiType) {
					setAiType(userInfo.aiType);
				}
			} catch (error) {
				console.error("Failed to fetch AI type:", error);
			}
		};

		if (auth.currentUser) {
			fetchAiType();
		}
	}, [auth.currentUser]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="flex flex-row justify-between p-4">
			<div className="ai-toggle relative">
				<button onClick={handleShowSelect}>
					<div className="w-40 md:w-56 border border-gray-600 border-1 rounded flex flex-row items-center justify-between px-2 py-1">
						<span>{aiType}</span>
						<FaChevronDown className="w-3 h-3" />
					</div>
				</button>
				<button onClick={handleShowOptions}>
					<div className="w-40 md:w-56 border border-gray-600 border-1 rounded flex flex-row items-center justify-between px-2 py-1">
						<span>{options}</span>
						<FaChevronDown className="w-3 h-3" />
					</div>
				</button>

				<div
					className={`absolute top-9 border border-gray-600 border-1 rounded-md w-full p-2 flex flex-col ${
						!isSelectOpen ? "hidden" : ""
					}`}
				>
					{/* Render AI models */}
					{["Gemini Pro 1.5", "GPT 4o", "o1-preview", "Claude 3.5"].map(
						(model) => (
							<button
								className="w-full p-1 flex items-center justify-between hover:bg-[#3D17DD] rounded-md"
								key={model}
								onClick={() =>
									updateAiType(
										model as "Gemini" | "GPT 4o" | "o1-preview" | "claude-3",
									)
								}
							>
								<span>{model}</span>
								{aiType === model && !isLoading && (
									<FaCheckCircle className="w-3 h-3" />
								)}
							</button>
						),
					)}
				</div>

				<div
					ref={optionsRef}
					className={`absolute top-9 border border-gray-600 border-1 rounded-md w-full p-2 flex flex-col ${
						!isOptionsOpen ? "hidden" : ""
					}`}
				>
					{["Options", "Personas", "Prompt Library", "Training Videos"].map(
						(option) => (
							<button
								className="w-full p-1 flex items-center justify-between hover:bg-[#3D17DD] rounded-md"
								key={option}
								onClick={() => {
									setOptions(
										option as
											| "Options"
											| "Personas"
											| "Prompt Library"
											| "Training Videos",
									);
									setIsOptionsOpen(false);
								}}
							>
								<span>{option}</span>
								{options === option && <FaCheckCircle className="w-3 h-3" />}
							</button>
						),
					)}
				</div>
			</div>

			<div className="flex gap-2">
				<button
					className="rounded-full bg-[#3D17DD] flex items-center justify-center w-12 h-12"
					onClick={handleShowSettings}
				>
					<IoSettingsOutline className="w-6 h-6" />
				</button>
				<button
					className="rounded-full bg-[#3D17DD] flex items-center justify-center w-12 h-12 account-controls"
					onClick={handleShowMenu}
				>
					<ProfilePicture width="12" height="12" />
				</button>
			</div>

			<div
				ref={settingsRef}
				className={`absolute right-20 w-36 bg-gray-700outline outline-1 outline-white rounded-md top-20 px-1 py-4 space-y-2 shadow-xl ${
					!isSettingsOpen ? "hidden" : ""
				}`}
			>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4">
					Users
				</button>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4">
					Audit Logs
				</button>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4">
					Insights
				</button>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4">
					Account
				</button>
			</div>

			<div
				ref={menuRef}
				className={`absolute right-6 w-36 bg-gray-700 outline outline-1 outline-white rounded-md top-20 px-1 py-4 space-y-2 shadow-xl ${
					!isMenuOpen ? "hidden" : ""
				}`}
			>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4 flex flex-row">
					<FaClipboard className="w-5 h-5 mr-2" />
					<a href="/clips">Clips</a>
				</button>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4 flex flex-row">
					<MdSubscriptions className="w-5 h-5 mr-2" />
					<a href="/account">My Plans</a>
				</button>
				<button
					className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4 flex flex-row"
					onClick={openModal}
				>
					<FaUser className="w-5 h-5 mr-2" />
					Profile
				</button>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4 flex flex-row">
					<HiSupport className="w-5 h-5 mr-2" />
					<a href="/support">Support</a>
				</button>
				<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4 flex flex-row">
					<IoExtensionPuzzle className="w-8 h-8 mr-2" />
					Integrations
				</button>
				<button
					className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4 flex flex-row"
					onClick={handleSignOut}
				>
					<FaSignOutAlt className="w-5 h-5 mr-2" />
					Sign Out
				</button>
			</div>
		</div>
	);
};

export default Topbar;
