import React from 'react';
import { Code } from 'lucide-react';

export const HowItWorks = () => (
  <div className="space-y-6 animate-fade-in">
    <h3 className="text-2xl font-semibold mb-4 flex items-center gap-2">
      <Code className="w-6 h-6 text-primary" />
      How It Works
    </h3>
    <div className="space-y-4 text-gray-300">
      <p>
        Our system analyzes your prompts and suggests improvements based on:
      </p>
      <ul className="list-disc list-inside space-y-2 ml-4">
        <li>Clarity and specificity</li>
        <li>Context inclusion</li>
        <li>Format structuring</li>
        <li>Goal alignment</li>
      </ul>
    </div>
  </div>
);