
import React from 'react';

export const LaptopPreview = () => {
  return (
    <div className="relative w-full max-w-2xl animate-fade-in-right">
      <img 
        src="/lovable-uploads/f6934e07-a5f9-479a-9289-349b4a6aa120.png" 
        alt="Application Preview" 
        className="w-full rounded-lg"
      />
    </div>
  );
};
