import React, { useState, useEffect } from "react";
import { LogIn, LogOut, User, MessageSquare } from "lucide-react";
import { SignInModal } from "./SignInModal";
import { SignUpModal } from "./SignUpModal";
import { supabase } from "../integrations/supabase/client";
import { useToast } from "../components/ui/use-toast";
import { Button } from "../components/ui/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { User as SupabaseUser } from "@supabase/supabase-js";

export const Header = () => {
	const [isSignInOpen, setIsSignInOpen] = useState(false);
	const [isSignUpOpen, setIsSignUpOpen] = useState(false);
	const [user, setUser] = useState<SupabaseUser | null>(null);
	const { toast } = useToast();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		// Check current session
		supabase.auth.getSession().then(({ data: { session } }) => {
			setUser(session?.user ?? null);
			if (session?.user && location.pathname === "/") {
				navigate("/chat");
			}
		});

		// Listen for auth changes
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setUser(session?.user ?? null);
			if (session?.user && location.pathname === "/") {
				navigate("/chat");
			}
		});

		return () => subscription.unsubscribe();
	}, [location.pathname, navigate]);

	const handleSignOut = async () => {
		try {
			const { error } = await supabase.auth.signOut();
			if (error) {
				toast({
					title: "Error signing out",
					description: error.message,
					variant: "destructive",
				});
			} else {
				setUser(null);
				navigate("/");
				toast({
					title: "Signed out successfully",
					description: "You have been signed out of your account.",
				});
			}
		} catch (error: any) {
			toast({
				title: "Error",
				description: error.message,
				variant: "destructive",
			});
		}
	};

	return (
		<header className="fixed top-0 left-0 right-0 z-[100] bg-navy/80 backdrop-blur-sm border-b border-white/10">
			<div className="container mx-auto px-4">
				<div className="flex items-center justify-between">
					<Link to={user ? "/chat" : "/"}>
						<img
							src="/lovable-uploads/Haivn_Logo_Finals_Opt 1 White.png"
							alt="haivn Logo"
							className="w-32"
						/>
					</Link>
					{user ? (
						<div className="flex items-center gap-4">
							<Link
								to="/profile"
								className="flex items-center gap-2 px-4 py-2 text-sm text-white hover:bg-white/10 rounded-md transition-colors"
							>
								<User className="w-4 h-4" />
								Profile
							</Link>
							<Button
								variant="ghost"
								size="sm"
								onClick={handleSignOut}
								className="flex items-center gap-2 text-white hover:bg-white/10"
							>
								<LogOut className="w-4 h-4" />
								Sign Out
							</Button>
						</div>
					) : (
						<Button
							variant="ghost"
							size="sm"
							onClick={() => navigate("/signin")}
							className="flex items-center gap-2 text-white hover:bg-white/10"
						>
							<LogIn className="w-4 h-4" />
							Sign In
						</Button>
					)}
				</div>
			</div>

			<SignInModal
				isOpen={isSignInOpen}
				onClose={() => setIsSignInOpen(false)}
				onSignUpClick={() => {
					setIsSignInOpen(false);
					setIsSignUpOpen(true);
				}}
			/>

			<SignUpModal
				isOpen={isSignUpOpen}
				onClose={() => setIsSignUpOpen(false)}
				onSignInClick={() => {
					setIsSignUpOpen(false);
					setIsSignInOpen(true);
				}}
			/>
		</header>
	);
};
