import React from "react";
import { Header } from "../components/Header";
import { Hero } from "../components/Hero";
import { LaptopPreview } from "../components/LaptopPreview";
import { AIProviders } from "../components/AIProviders";
import { Features } from "../components/Features";
import { Pricing } from "../components/Pricing";
import { GettingStarted } from "../components/GettingStarted";
import { SecurityFeatures } from "../components/SecurityFeatures";
import { FAQ } from "../components/FAQ";
import { PromptOptimization } from "../components/PromptOptimization";
import { FinalCTA } from "../components/FinalCTA";
import { Input } from "../components/ui/Input2";
import { useToast } from "../components/ui/use-toast";
const Index = () => {
	const { toast } = useToast();
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const email = formData.get("email");
		console.log("Email submitted:", email);
		toast({
			title: "Thanks for signing up!",
			description: "We'll keep you updated on our latest news.",
		});
		e.currentTarget.reset();
	};
	return (
		<div className="min-h-screen bg-[#0B1215]">
			<Header />

			<div className="pt-16">
				<div className="container px-4 md:py-24 py-[33px]">
					<div className="flex flex-col lg:flex-row items-center gap-16">
						<div className="lg:w-1/2 animate-fade-in">
							<Hero />
							<AIProviders />
						</div>
						<div className="lg:w-1/2 flex flex-col items-center gap-8 animate-fade-in-right">
							<LaptopPreview />
							<div className="w-full max-w-md">
								<p className="text-center text-[#D6DADB] mb-4">
									Stay updated with our latest features
								</p>
								<form onSubmit={handleSubmit} className="flex gap-2">
									<Input
										type="email"
										name="email"
										placeholder="Enter your email"
										className="bg-white/5 border-white/10 text-[#D6DADB] placeholder:text-gray-400"
										required
									/>
									<button
										type="submit"
										className="btn-primary h-10 px-4 text-sm whitespace-nowrap"
									>
										Get Updates
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div className="space-y-16">
					<div
						className="opacity-0 translate-y-8 transition-all duration-700 ease-out"
						style={{
							animationFillMode: "forwards",
						}}
						data-animate-on-scroll
					>
						<Features />
					</div>

					<div
						className="opacity-0 translate-y-8 transition-all duration-700 ease-out delay-100"
						style={{
							animationFillMode: "forwards",
						}}
						data-animate-on-scroll
					>
						<PromptOptimization />
					</div>

					<div
						className="opacity-0 translate-y-8 transition-all duration-700 ease-out delay-200"
						style={{
							animationFillMode: "forwards",
						}}
						data-animate-on-scroll
					>
						<Pricing />
					</div>

					<div
						className="opacity-0 translate-x-8 transition-all duration-700 ease-out delay-100"
						style={{
							animationFillMode: "forwards",
						}}
						data-animate-on-scroll
					>
						<GettingStarted />
					</div>

					<div
						className="opacity-0 -translate-x-8 transition-all duration-700 ease-out"
						style={{
							animationFillMode: "forwards",
						}}
						data-animate-on-scroll
					>
						<SecurityFeatures />
					</div>

					<div
						className="opacity-0 scale-95 transition-all duration-700 ease-out delay-100"
						style={{
							animationFillMode: "forwards",
						}}
						data-animate-on-scroll
					>
						<FAQ />
					</div>

					<div
						className="opacity-0 translate-y-8 transition-all duration-700 ease-out"
						style={{
							animationFillMode: "forwards",
						}}
						data-animate-on-scroll
					>
						<FinalCTA />
					</div>
				</div>
			</div>
		</div>
	);
};
if (typeof window !== "undefined") {
	const observerCallback: IntersectionObserverCallback = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add(
					"opacity-100",
					"translate-y-0",
					"translate-x-0",
					"scale-100",
				);
			}
		});
	};
	const observerOptions = {
		root: null,
		rootMargin: "0px",
		threshold: 0.1,
	};
	if ("IntersectionObserver" in window) {
		const observer = new IntersectionObserver(
			observerCallback,
			observerOptions,
		);
		setTimeout(() => {
			document
				.querySelectorAll("[data-animate-on-scroll]")
				.forEach((element) => {
					observer.observe(element);
				});
		}, 100);
	}
}
export default Index;
