import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUpPage from "./pages/signUp";
import SignInPage from "./pages/signIn";
import ChatPage from "./pages/chat";
import Account from "./pages/account";
import Home from "./pages/home";
import Clips from "./pages/clips";
import Support from "./pages/support";
import { ThemeProvider } from "./context/themeContext";

const Router = () => {
	return (
		<ThemeProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					{/* <Route path="/signup" element={<SignUpPage />} /> */}
					<Route path="/signin" element={<SignInPage />} />
					<Route path="/chat" element={<ChatPage />} />
					<Route path="/account" element={<Account />} />
					<Route path="/clips" element={<Clips />} />
					<Route path="/support" element={<Support />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default Router;
