import React from "react";
import { Code } from "lucide-react";
import { HowItWorks } from "./prompt/HowItWorks";
import { RealTimeOptimization } from "./prompt/RealTimeOptimization";
import { BeforeAfterExamples } from "./prompt/BeforeAfterExamples";
export const PromptOptimization = () => {
	return (
		<section className="py-12 bg-navy-light relative overflow-hidden">
			<div className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" />

			<div className="container px-4 relative py-0">
				<div className="max-w-3xl mx-auto text-center mb-16">
					<div className="flex justify-center mb-4">
						<Code className="w-12 h-12 text-primary animate-pulse" />
					</div>
					<h2 className="text-4xl font-bold mb-4">Prompt Optimization</h2>
					<div className="h-1 w-20 bg-primary mx-auto mb-6 rounded-full"></div>
					<p className="text-gray-300 text-lg">
						Our built-in prompt optimization tools help you craft more effective
						prompts that generate higher quality responses from AI models.
					</p>
				</div>

				<div className="grid md:grid-cols-2 gap-8 mb-12">
					<HowItWorks />
					<RealTimeOptimization />
				</div>

				<BeforeAfterExamples />
			</div>
		</section>
	);
};
