import React from 'react';
import { Sparkles } from 'lucide-react';

export const RealTimeOptimization = () => (
  <div className="glass-card p-8 animate-fade-in-right">
    <div className="flex items-center gap-2 mb-4">
      <Sparkles className="w-6 h-6 text-primary" />
      <h3 className="text-xl font-semibold">Real-time Optimization</h3>
    </div>
    <p className="text-gray-300 mb-4">
      As you type, our system provides suggestions to enhance your prompts, 
      ensuring you get the most accurate and relevant responses.
    </p>
  </div>
);