import React from 'react';

export const AIProviders = () => {
  return (
    <div className="mt-16 animate-fade-in">
      <p className="text-sm text-gray-400 mb-6">AI Conversations Powered by:</p>
      <div className="flex items-center gap-8">
        <img 
          src="/lovable-uploads/e09a3984-2fff-4a8b-a4e8-1692bc16f74a.png" 
          alt="Anthropic Claude" 
          className="h-12" 
        />
        <img 
          src="/lovable-uploads/46d37473-e7b7-4317-9bdb-8083c783210e.png" 
          alt="Google Gemini" 
          className="h-12" 
        />
        <img 
          src="/lovable-uploads/f7382468-0658-4ca7-803b-5cc8e88aec51.png" 
          alt="GPT" 
          className="h-12" 
        />
      </div>
    </div>
  );
};