import { auth } from "../../firebase/config";
import { Chat, Folder } from "../../types/index";
import generateFunctionUrl from "../../utils/generateFunctionUrl";

export const folderService = {
	getFolders: async (idToken: string): Promise<Folder[]> => {
		const response = await fetch(generateFunctionUrl("getFolders"), {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (!response.ok) {
			throw new Error("Failed to fetch folders");
		}

		const data = await response.json();
		return data.folders;
	},

	getFolderChats: async (
		folderId: string,
		idToken: string,
	): Promise<Chat[]> => {
		const response = await fetch(generateFunctionUrl(`getFolderChats`), {
			method: "POST",
			headers: {
				Authorization: `Bearer ${idToken}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ folderId }),
		});
		if (!response.ok) {
			throw new Error("Failed to fetch folder chats");
		}

		const data = await response.json();
		return data.chats;
	},
	deleteFolder: async (folderId: string, idToken: string) => {
		const response = await fetch(generateFunctionUrl("deleteFolder"), {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${idToken}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ folderId }),
		});
		if (!response.ok) {
			throw new Error("Failed to delete folder");
		}

		const data = await response.json();
		return data.success;
	},
	renameFolder: async (
		folderId: string,
		newName: string | null,
		emoji: string | null,
		color: string | null,
		idToken: string,
	) => {
		const response = await fetch(generateFunctionUrl("renameFolder"), {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({ folderId, newName, emoji, color }), // Include color in the request
		});
		if (!response.ok) {
			throw new Error("Failed to rename folder");
		}

		const data = await response.json();
		return data.success;
	},

	createFolder: async (name: string, idToken: string) => {
		const response = await fetch(generateFunctionUrl("createFolder"), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({ name }),
		});
		if (response.status === 403) {
			const errorMessage = await response.text();
			if (errorMessage === "Folder limit reached") {
				alert(
					"You have reached the maximum number of folders. Please upgrade or delete an existing folder.",
				);
			} else {
				alert("You are not authorized to create more folders.");
			}
			throw new Error("Folder creation limit reached");
		}

		if (!response.ok) {
			throw new Error("Failed to create folder");
		}

		const data = await response.json();
		return data.success;
	},
	updateFolderParent: async (
		folderId: string,
		parentFolderId: string | null,
	) => {
		try {
			if (folderId === parentFolderId) {
				throw new Error("A folder cannot be its own parent");
			}

			const idToken = await auth.currentUser?.getIdToken();

			const response = await fetch(generateFunctionUrl("updateFolderParent"), {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify({
					folderId,
					parentFolderId, // Pass parentFolderId or null to remove the parent relationship
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to update folder parent");
			}

			const data = await response.json();
			return data;
		} catch (error) {
			console.error("Error updating folder parent:", error);
			throw error;
		}
	},
	getSubFolders: async (
		parentFolderId: string,
		idToken: string,
	): Promise<Folder[]> => {
		const response = await fetch(generateFunctionUrl("getSubFolders"), {
			method: "POST",
			headers: {
				Authorization: `Bearer ${idToken}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ parentFolderId }),
		});

		if (!response.ok) {
			throw new Error("Failed to fetch subfolders");
		}

		const data = await response.json();
		return data.folders;
	},
};
