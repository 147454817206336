/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm"; // Import the remark-gfm plugin

interface IMarkdownProps {
	data: string; // The markdown content as a string
	markdownClassesProps?: string;
}

const Markdown: React.FC<IMarkdownProps> = ({ data, markdownClassesProps }) => {
	// Define custom renderers for Markdown components, including table elements
	const markdownRenderers: Components = {
		h1: ({ node, ...props }) => (
			<h1 className="text-4xl font-semibold" {...props} />
		),
		h2: ({ node, ...props }) => (
			<h2 className="text-2xl font-semibold" {...props} />
		),
		h3: ({ node, ...props }) => <h3 className="text-xl font-bold" {...props} />,
		h4: ({ node, ...props }) => (
			<h4 className="text-lg font-bold underline" {...props} />
		),
		h5: ({ node, ...props }) => (
			<h5 className="text-base font-bold underline" {...props} />
		),
		p: ({ node, ...props }) => (
			<p className="text-base font-light mb-4 text-white" {...props} />
		),
		ul: ({ node, ...props }) => <ul className="mb-4" {...props} />,
		li: ({ node, ...props }) => (
			<li className="list-disc ml-6 font-thin py-2 text-white" {...props} />
		),
		hr: ({ node, ...props }) => (
			<hr className="border-t-[1px] border-white opacity-30 my-4" {...props} />
		), // Custom divider line
		strong: ({ node, ...props }) => (
			<strong className="font-semibold" {...props} />
		),
		em: ({ node, ...props }) => <em className="italic" {...props} />,
		del: ({ node, ...props }) => <del className="line-through" {...props} />,

		// Custom table renderer with solid lines and shaded headers
		table: ({ node, ...props }) => (
			<table
				className="w-full border-collapse border border-white text-white"
				{...props}
			/>
		),
		thead: ({ node, ...props }) => <thead className="bg-gray-800" {...props} />,
		th: ({ node, ...props }) => (
			<th
				className="border border-white px-4 py-2 text-left font-bold bg-gray-700"
				{...props}
			/>
		),
		tbody: ({ node, ...props }) => (
			<tbody className="divide-y divide-white" {...props} />
		),
		td: ({ node, ...props }) => (
			<td className="border border-white px-4 py-2" {...props} />
		),
		pre: ({ node, inline, className, children, ...props }: any) => {
			return (
				<Markdown
					data={children?.props?.children}
					markdownClassesProps={"text-brand-green-dark hover:text-green-dark "}
				/>
			);
		},
	};

	return (
		<ReactMarkdown
			className={markdownClassesProps || "text-white"}
			components={markdownRenderers}
			rehypePlugins={[rehypeRaw]}
			remarkPlugins={[remarkGfm]} // Add remark-gfm to support tables
		>
			{data}
		</ReactMarkdown>
	);
};

export default Markdown;
