import React from 'react';
import { TypewriterText } from '../TypewriterText';

interface PromptExampleProps {
  title: string;
  prompt: string;
  startDelay?: number;
  className?: string;
}

export const PromptExample = ({ title, prompt, startDelay = 0, className = "" }: PromptExampleProps) => (
  <div className={`glass-card p-6 ${className}`}>
    <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
      {title}
    </h3>
    <p className="text-gray-300 font-mono text-sm bg-navy/50 p-4 rounded-lg">
      <TypewriterText text={prompt} delay={50} startDelay={startDelay} />
    </p>
  </div>
);