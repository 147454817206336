import { collection, orderBy, onSnapshot, query } from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { auth, db } from "../../firebase/config";
import { Message } from "../../types/index";
import { useChatContext } from "../../context/chatContext";
import { chatService } from "../../services/chat";
import Markdown from "./markdown";
import { BsPinAngle } from "react-icons/bs";
import { userService } from "../../services/user";

import {
	IoArrowUpCircleOutline,
	IoMicOutline,
	IoAttachOutline,
} from "react-icons/io5";
import { Share2 } from "lucide-react";

const ChatWindow = () => {
	const [messages, setMessages] = useState<Message[]>([]);
	const [isMessageLoading, setIsMessageLoading] = useState(false);
	const [isResponseLoading, setIsResponseLoading] = useState(false);
	const [streamingMessage, setStreamingMessage] = useState<string>(""); // Single message block for streaming
	const [newMessage, setNewMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const {
		activeChat,
		setActiveChat,
		setChatsOutsideFolders,
		setTokenCount,
		setFolderChats,
	} = useChatContext();
	const location = useLocation();
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const chatId = params.get("chatId");
		if (chatId) {
			setActiveChat(chatId);
		}
	}, [location, setActiveChat]);
	// Ref to the chat container
	const chatContainerRef = useRef<HTMLDivElement>(null);

	const textareaRef = useRef<HTMLTextAreaElement>(null);

	// Auto scroll function
	const scrollToBottom = () => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	};

	useEffect(() => {
		console.log("chats loading");
	}, [isMessageLoading]);

	useEffect(() => {
		if (!auth.currentUser || !activeChat) return;

		setIsMessageLoading(true);

		//Focus on the textarea
		if (textareaRef.current) {
			textareaRef.current.focus();
		}

		// Query for messages in the active chat
		const messagesQuery = query(
			collection(db, `chats/${activeChat}/messages`),
			orderBy("timestamp", "asc"),
		);

		const unsubscribeMessages = onSnapshot(messagesQuery, (querySnapshot) => {
			const messagesData = querySnapshot.docs.map((doc) => {
				const data = doc.data();

				let messageTimestamp;
				if (data.timestamp && typeof data.timestamp.toDate === "function") {
					messageTimestamp = data.timestamp.toDate();
				} else if (typeof data.timestamp === "number") {
					messageTimestamp = new Date(data.timestamp);
				} else {
					messageTimestamp = new Date();
				}

				return {
					id: doc.id,
					content: data.content,
					role: data.role,
					timestamp: messageTimestamp,
				};
			}) as Message[];

			// Replace the streamed message once the full message is available from Firestore
			setMessages(messagesData);
			setIsMessageLoading(false);

			// Clear the streaming message since the full message is now available in Firestore
			setStreamingMessage("");
			setIsMessageLoading(false);
		});

		return () => {
			unsubscribeMessages();
		};
	}, [activeChat]);

	// Scroll to bottom whenever new messages or streamingMessage changes
	useEffect(() => {
		scrollToBottom();
	}, [messages, streamingMessage]);

	const handleStartChat = async () => {
		if (!newMessage.trim() || !auth.currentUser) return;

		setIsLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();
			const chatName = "New Chat";
			const newChatId = await chatService.createChat(chatName, idToken, null);

			if (newChatId) {
				setActiveChat(newChatId);
				await handleSendMessage(newChatId); // Pass the new chat ID to send the initial message

				// Refresh the chats outside folders list
				const chats = await chatService.getGlobalChats(idToken);
				setChatsOutsideFolders(chats);
			}
		} catch (error) {
			console.error("Error starting a new chat:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSendMessage = async (chatId = activeChat) => {
		if (!newMessage.trim() || !auth.currentUser || !chatId) return;

		const messageContent = newMessage;
		setNewMessage(""); // Clear input immediately
		setIsLoading(true);
		setIsResponseLoading(true);
		//setMessageLoading(true);
		try {
			const idToken = await auth.currentUser.getIdToken();

			// Add user's message to messages state
			setMessages((prevMessages) => [
				...prevMessages,
				{
					id: Math.random().toString(),
					content: messageContent, // Use saved message content
					role: "user",
					timestamp: new Date(),
				},
			]);

			let fullResponse = "";

			// Define the same delimiter used in the backend
			const TITLE_DELIMITER = "###TITLE_DELIMITER###";

			// Handle streaming response chunks
			await chatService.askAI(
				newMessage,
				chatId,
				idToken,
				(chunk) => {
					if (chunk.includes(TITLE_DELIMITER)) {
						// Split the chunk to extract the title
						const [beforeTitle, afterTitle] = chunk.split(TITLE_DELIMITER);

						if (beforeTitle) {
							setStreamingMessage((prevMessage) => prevMessage + beforeTitle);
						}

						if (afterTitle) {
							const title = afterTitle.trim();

							// Update chat title in both folders and outside folders
							setChatsOutsideFolders((prevChats) =>
								prevChats.map((chat) =>
									chat.id === chatId ? { ...chat, name: title } : chat,
								),
							);

							// Update chats in folders
							setFolderChats((prevFolderChats) => {
								const newFolderChats = { ...prevFolderChats };
								// Loop through all folder chats
								Object.keys(newFolderChats).forEach((folderId) => {
									newFolderChats[folderId] = newFolderChats[folderId].map(
										(chat) =>
											chat.id === chatId ? { ...chat, name: title } : chat,
									);
								});
								return newFolderChats;
							});
						}
					} else {
						setStreamingMessage((prevMessage) => prevMessage + chunk);
					}
					fullResponse += chunk;
				},
				usePromptOptimizer,
			);

			//setIsMessageLoading(false);
			//setIsResponseLoading(false);

			// Fetch updated token count after message is sent
			const updatedTokens = await userService.getUserTokens();
			setTokenCount(updatedTokens);
		} catch (error) {
			console.error("Error sending message:", error);
		} finally {
			setIsLoading(false);
			setIsMessageLoading(false);
			setIsResponseLoading(false);
		}
	};

	const saveClip = async (
		messageContent: string,
		chatId: string,
		messageIndex: number,
	) => {
		try {
			// Find the preceding user message
			const userMessage = messages[messageIndex - 1];
			await chatService.saveClip(userMessage.content, messageContent, chatId);
			alert("Clip saved successfully!");
		} catch (error) {
			console.error("Error saving clip:", error);
			alert("Failed to save clip");
		}
	};

	// Add state for prompt optimizer
	const [usePromptOptimizer, setUsePromptOptimizer] = useState(true);

	return (
		<main className="flex flex-col h-screen bg-gray-200 dark:bg-gray-800">
			{/* Reference the chat container for auto-scroll */}
			<div
				ref={chatContainerRef}
				className="flex-1 overflow-y-auto px-6 py-8"
				style={{ scrollBehavior: "smooth" }} // Smooth scrolling effect
			>
				{!activeChat ? ( //New chat
					<div className="flex flex-col items-center justify-center h-full">
						<p className="text-gray-600 dark:text-gray-300 mb-4 text-lg">
							Type a question to start a new chat
						</p>
						<div className="relative w-full md:w-1/2">
							<textarea
								ref={textareaRef}
								value={newMessage}
								onChange={(e) => setNewMessage(e.target.value)}
								placeholder="Type your question"
								className="w-full border border-gray-500 rounded-xl px-4 py-2 pr-12 focus:outline-none focus:ring-2 bg-gray-500 focus:ring-primary dark:text-white"
								rows={2}
								style={{
									resize: "none",
									maxHeight: `${10 * 1.5}em`, // Adjust based on line height (1.5em per line assumed)
									overflowY: "auto", // Show scrollbar when max height is reached
								}}
								onInput={(e) => {
									const target = e.target as HTMLTextAreaElement;
									target.style.height = "auto";
									target.style.height = `${target.scrollHeight}px`;
								}}
								onKeyDown={(e) => {
									if (e.key === "Enter" && !e.shiftKey) {
										e.preventDefault();
										handleStartChat();
									}
								}}
							/>
							{isResponseLoading ? (
								<div className="absolute right-5 top-[65%] transform -translate-y-1/2 flex items-center justify-center">
									<span className="loaderX">&nbsp;</span>
								</div>
							) : (
								<div>
									<button
										onClick={handleStartChat}
										disabled={isLoading}
										className="absolute right-3 top-[63%] transform -translate-y-1/2 text-purple-200 hover:text-purple-600 focus:outline-none flex items-center justify-center"
									>
										<IoArrowUpCircleOutline size={32} />
									</button>
									<button
										disabled={true}
										className="absolute right-10 top-[63%] transform -translate-y-1/2 text-gray-400 focus:outline-none flex items-center justify-center"
									>
										<IoAttachOutline size={32} />
									</button>
									<button
										disabled={true}
										className="absolute right-16 top-[63%] transform -translate-y-1/2 text-gray-400 focus:outline-none flex items-center justify-center"
									>
										<IoMicOutline size={32} />
									</button>
								</div>
							)}
						</div>
					</div>
				) : (
					<div className="flex flex-col items-start">
						{/* Check for messageLoading */}
						{isMessageLoading ? (
							<div className="flex items-center justify-center w-full h-96 gap-4">
								<div className="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full animate-spin pe-4"></div>
							</div>
						) : (
							/* Render messages from Firestore */
							messages.map((message, index) => (
								<div
									key={message.id}
									className={`mb-4 max-w-[80%] ${
										message.role === "user" ? "self-end" : "self-start"
									}`}
								>
									{message.role === "user" ? (
										<div className="bg-gray-700 rounded-lg p-4">
											<p className="text-white">{message.content}</p>
										</div>
									) : (
										<div className="bg-gray-700 rounded-lg p-4">
											<Markdown data={message.content} />
											<div className="flex items-center gap-2 mt-2">
												<button
													className="text-sm text-purple-500 hover:text-purple-600 flex items-center gap-2 mt-2"
													onClick={() =>
														saveClip(
															message.content,
															activeChat,
															messages.indexOf(message),
														)
													}
												>
													<BsPinAngle size={16} /> Save Clip
												</button>
												<button className="text-sm text-purple-500 hover:text-purple-600 flex items-center gap-2 mt-2">
													<Share2 className="h-4 w-4 inline-block mr-2" />
													Share Clip
												</button>
											</div>
										</div>
									)}
								</div>
							))
						)}

						{/* Render streaming message */}
						{streamingMessage && (
							<div className="mb-4 max-w-[80%] self-start">
								<div className="bg-gray-700 rounded-lg p-4">
									<Markdown data={streamingMessage} />
								</div>
							</div>
						)}
					</div>
				)}
			</div>

			{activeChat && (
				<div className="bg-white dark:bg-gray-800 p-4 dark:border-gray-600">
					<div className="container mx-auto pb-20">
						<div className="relative">
							<div className="flex items-center gap-3 mb-2">
								{/* Toggle Switch */}
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										className="sr-only peer"
										checked={usePromptOptimizer}
										onChange={() => setUsePromptOptimizer(!usePromptOptimizer)}
									/>
									<div
										className={`w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white ${
											usePromptOptimizer
												? "bg-green-500 dark:bg-green-600"
												: "bg-gray-200 dark:bg-gray-700"
										}`}
									></div>
									<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
										Prompt Optimizer
									</span>
								</label>
							</div>

							<textarea
								ref={textareaRef}
								value={newMessage}
								onChange={(e) => setNewMessage(e.target.value)}
								placeholder="Type your question"
								className="w-full border border-gray-500 rounded-xl px-4 py-2 pr-12 focus:outline-none focus:ring-2 bg-gray-500 focus:ring-primary dark:text-white"
								rows={2}
								style={{
									resize: "none",
									maxHeight: `${10 * 1.5}em`, // Adjust based on line height (1.5em per line assumed)
									overflowY: "auto", // Show scrollbar when max height is reached
								}}
								onInput={(e) => {
									const target = e.target as HTMLTextAreaElement;
									target.style.height = "auto";
									target.style.height = `${target.scrollHeight}px`;
								}}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										if (e.shiftKey) {
											// If Shift+Enter, insert a new line
											e.preventDefault(); // Prevent the default Enter key behavior
											setNewMessage((prevMessage) => prevMessage + "\n");
										} else {
											// If only Enter, send the message
											e.preventDefault(); // Prevent the default Enter key behavior
											handleSendMessage();
										}
									}
								}}
							/>
							{/* Replace the button with a loader while getting the new messages */}
							{isResponseLoading ? (
								<div className="absolute right-5 top-[65%] transform -translate-y-1/2 flex items-center justify-center">
									<span className="loaderX">&nbsp;</span>
								</div>
							) : (
								<div>
									<button
										onClick={() => {
											handleSendMessage(); // Send the message
											const textarea = document.querySelector("textarea");
											if (textarea) {
												textarea.style.height = "auto";
											}
										}}
										disabled={isLoading}
										className="absolute right-3 top-[63%] transform -translate-y-1/2 text-purple-200 hover:text-purple-600 focus:outline-none flex items-center justify-center"
									>
										<IoArrowUpCircleOutline size={32} />
									</button>
									<button
										disabled={true}
										className="absolute right-10 top-[63%] transform -translate-y-1/2 text-gray-400 focus:outline-none flex items-center justify-center"
									>
										<IoAttachOutline size={32} />
									</button>
									<button
										disabled={true}
										className="absolute right-16 top-[63%] transform -translate-y-1/2 text-gray-400 focus:outline-none flex items-center justify-center"
									>
										<IoMicOutline size={32} />
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</main>
	);
};

export default ChatWindow;
