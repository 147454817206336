import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";


interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (isOpen) {
          setShowModal(true); // Start transition to open modal
        } else {
          const timeout = setTimeout(() => setShowModal(false), 300); // Wait for transition to complete before unmounting
          return () => clearTimeout(timeout); // Cleanup timeout if unmounted
        }
      }, [isOpen]);

    if (!isOpen && !showModal) return null;

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-65 flex justify-center items-center z-50 transition-opacity duration-100 ${
                isOpen ? "opacity-100" : "opacity-0"
            }`}
        >
            <div
                className={`bg-gray-800 p-6 rounded-lg shadow-lg relative transform transition-transform duration-100 border border-gray-700 w-1/3 h-auto ${
                isOpen ? "scale-100" : "scale-90"
                }`}
            >
                <div className="flex flex-row justify-between">
                    {title && <h2 className="text-xl font-semibold mb-4">{title}</h2>}
                    <button
                        onClick={onClose}
                        className="text-l text-white hover:bg-gray-500 focus:outline-none flex items-center p-0 justify-center rounded-full w-6 h-6"
                        >
                        <FaTimes />
                    </button>
                </div>
                
                <div className="bg-neutral-700 mt-2 h-[1px] w-full" />
                <div>{children}</div>
            </div>
        </div>


    );
}

export default Modal;