import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/config";
import { clipService } from "../services/clip";
import logo from "../assets/folderly-logo.png";

interface Clip {
	id: string;
	question: string;
	answer: string;
	chatId: string;
	timestamp: Date;
}

const ClipsPage: React.FC = () => {
	const [clips, setClips] = useState<Clip[]>([]);
	const [isMobileView, setIsMobileView] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const navigate = useNavigate();

	const title = "Saved Clips";

	// Fetch authentication state
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((currentUser) => {
			setIsAuthenticated(!!currentUser);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		// Function to check the screen size
		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Fetch clips once authenticated
	useEffect(() => {
		if (isAuthenticated) {
			const fetchClips = async () => {
				try {
					const fetchedClips = await clipService.getClips();
					setClips(
						fetchedClips.map((clip: any) => ({
							...clip,
							timestamp: new Date(clip.timestamp), // Convert timestamp to Date
						})),
					);
				} catch (error) {
					console.error("Error fetching clips:", error);
				}
			};
			fetchClips();
		}
	}, [isAuthenticated]);

	// Delete a clip
	const deleteClip = async (clipId: string) => {
		try {
			await clipService.deleteClip(clipId);
			setClips((prevClips) => prevClips.filter((clip) => clip.id !== clipId));
		} catch (error) {
			console.error("Error deleting clip:", error);
		}
	};

	// Handle navigation to chat with active chat ID
	const handleClipClick = (chatId: string) => {
		navigate(`/chat?chatId=${chatId}`);
	};

	return (
		<div className="min-h-screen bg-gray-100 dark:bg-gray-900">
			{/* Header Section */}
			{!isMobileView ? (
				<header className="flex items-center justify-between p-6 bg-gray-800 dark:bg-gray-800 text-white shadow-md">
					<img
						src={logo}
						alt="Folderly AI Platform"
						className="w-48 h-auto object-contain"
					/>
					<h1 className="text-2xl font-bold">{title}</h1>
					<button
						onClick={() => navigate("/chat")}
						className="bg-purple-600 hover:bg-[#3D17DD] px-4 py-2 rounded-md text-white font-semibold transition"
					>
						Back to Chats
					</button>
				</header>
			) : (
				<header className="flex flex-col gap-6 items-center justify-between p-6 bg-gray-800 dark:bg-gray-800 text-white shadow-md">
					<div className="flex flex-row items-center justify-between w-full">
						<img
							src={logo}
							alt="Folderly AI Platform"
							className="w-48 h-auto object-contain"
						/>
						<button
							onClick={() => navigate("/chat")}
							className="bg-purple-600 hover:bg-[#3D17DD] px-4 py-2 rounded-md text-white font-semibold transition"
						>
							Back
						</button>
					</div>
					<div>
						<h1 className="text-2xl font-bold">{title}</h1>
					</div>
				</header>
			)}

			{/* Clips Display Section */}
			<div className="p-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
				{clips.length > 0 ? (
					clips.map((clip, index) => (
						<div
							key={clip.id}
							className={`relative group bg-gray-800 dark:bg-gray-800 shadow-md border border-gray-700 rounded-lg 
								hover:shadow-lg transition-all duration-200 overflow-hidden
								transform hover:-translate-y-1 hover:rotate-${index % 2 ? "1" : "-1"}`}
						>
							<div
								onClick={() => handleClipClick(clip.chatId)}
								className="p-6 h-full cursor-pointer"
							>
								<p className="text-gray-100 dark:text-gray-100 mb-1 font-bold">
									Question:
								</p>
								<p className="text-gray-100 dark:text-gray-100 text-sm line-clamp-4 mb-3 font-handwriting">
									{clip.question}
								</p>
								<br />
								<p className="text-gray-100 dark:text-gray-100 mb-1 font-bold">
									Answer:
								</p>
								<p className="text-gray-100 dark:text-gray-100 text-sm line-clamp-4 mb-3 font-handwriting">
									{clip.answer}
								</p>
								<p className="text-gray-400 dark:text-gray-400 font-mono text-xs">
									{clip.timestamp.toLocaleString()}
								</p>
							</div>
							<button
								onClick={(e) => {
									e.stopPropagation();
									deleteClip(clip.id);
								}}
								className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 
									bg-red-900 hover:bg-red-800 text-red-100 
									p-1.5 rounded-full transition-opacity duration-200"
							>
								<svg
									className="w-4 h-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					))
				) : (
					<p className="text-gray-600 dark:text-gray-400 col-span-full text-center">
						No saved clips found.
					</p>
				)}
			</div>
		</div>
	);
};

export default ClipsPage;
