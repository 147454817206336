import React, { useState } from "react";
import { Badge } from "../components/ui/badge";
import { SignUpModal } from "./SignUpModal";
import { CheckCircle } from "lucide-react";
const PricingCard = ({
	title,
	price,
	originalPrice,
	features,
	onGetStarted,
}: {
	title: string;
	price: string;
	originalPrice?: string;
	features: string[];
	onGetStarted: () => void;
}) => (
	<div className="glass-card p-8 flex flex-col h-full animate-fade-in relative transition-all duration-300 hover:shadow-[0_0_25px_rgba(155,135,245,0.15)] hover:scale-[1.02]">
		{originalPrice && (
			<div className="absolute -top-3 -right-3">
				<Badge className="bg-primary text-white font-semibold px-3 py-1.5 shadow-lg">
					50% OFF - Limited Time
				</Badge>
			</div>
		)}
		<h3 className="text-2xl font-bold mb-2">{title}</h3>
		<div className="flex flex-col mb-6">
			{originalPrice && (
				<p className="text-xl font-bold text-gray-400 line-through mb-1">
					{originalPrice}
				</p>
			)}
			<p className="text-4xl font-bold text-primary">{price}</p>
		</div>
		<ul className="space-y-4 text-gray-300 flex-grow">
			{features.map((feature, index) => (
				<li key={index} className="flex items-center gap-2">
					<CheckCircle className="min-w-4 min-h-4 w-4 h-4 text-primary" />
					{feature}
				</li>
			))}
		</ul>
		<button className="btn-primary mt-8 w-full" onClick={onGetStarted}>
			Get Started
		</button>
	</div>
);
export const Pricing = () => {
	const [isSignUpOpen, setIsSignUpOpen] = useState(false);
	const plans = [
		{
			title: "Basic Plan",
			originalPrice: "$20/mo",
			price: "$10/mo",
			features: [
				"5,000 free AI chats per month",
				"Unlimited folders",
				"Access to OpenAI GPT-4o, GPT-o1 preview, Claude 3.5 & Gemini Pro 1.5",
			],
		},
		{
			title: "Business Plan",
			originalPrice: "$40/mo",
			price: "$20/mo",
			features: [
				"10,000 free AI chats per month",
				"Unlimited folders",
				"Access to OpenAI GPT-4o, GPT-o1 preview, Claude 3.5 & Gemini Pro 1.5",
				"24/7 Premium Support",
				"Advanced Reporting",
				"Audit Logs",
				"Shareable folders"
			],
		},
		{
			title: "Enterprise Plan",
			originalPrice: "",
			price: "Contact Sales",
			features: [
				"Unlimited free AI chats per month*",
				"Unlimited folders",
				"Access to OpenAI GPT-4o, GPT-o1 preview, Claude 3.5 & Gemini Pro 1.5",
				"Priority Support",
				"24/7 Premium Support",
				"Advanced Analytics",
				"Audit Logs",
				"Shareable folders",
				"Custom Integrations",
				"Data Retention",
				"Dedicated Account Manager",
			],
		},
	];
	return (
		<section className="container px-4 py-[39px]">
			<h2 className="text-4xl md:text-5xl font-bold text-center mb-16">
				Find the Right Plan for You
			</h2>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
				{plans.map((plan, index) => (
					<PricingCard
						key={index}
						title={plan.title}
						price={plan.price}
						originalPrice={plan.originalPrice}
						features={plan.features}
						onGetStarted={() => setIsSignUpOpen(true)}
					/>
				))}
			</div>

			<SignUpModal
				isOpen={isSignUpOpen}
				onClose={() => setIsSignUpOpen(false)}
			/>
		</section>
	);
};
