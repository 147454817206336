import React from 'react';
import { Layout, Brain, ShieldCheck, FolderTree, Building, LucideDatabase } from 'lucide-react';
const features = [{
  icon: <Layout className="w-6 h-6 text-primary" />,
  title: "Aggregated Access to Major LLMs",
  description: "Haivn provides users with unified access to leading LLMs, streamlining AI adoption while ensuring cost-effectiveness."
}, {
  icon: <ShieldCheck className="w-6 h-6 text-primary" />,
  title: "Data Leakage Protection",
  description: "The platform encrypts all user data and ensures that LLMs cannot train on proprietary information, reducing the risk of data exposure and aligning with best practices in AI governance."
}, {
  icon: <Brain className="w-6 h-6 text-primary" />,
  title: "Ease of Use & Custom Training",
  description: "Haivn’s intuitive interface and customizable training modules help SMBs achieve 30%–50% efficiency gains, accelerating AI adoption without requiring deep technical expertise."
}, {
  icon: <FolderTree className="w-6 h-6 text-primary" />,
  title: "Collaboration & Productivity Tools",
  description: "Features like prompt optimization, response sharing, and customizable folders enhance productivity and teamwork within organizations."
}, {
  icon: <Building className="w-6 h-6 text-primary" />,
  title: "Audit & Reporting",
  description: "Businesses gain actionable insights into AI usage, compliance, and security through detailed audit logs and reporting, ensuring responsible AI deployment."
}, {
  icon: <LucideDatabase className="w-6 h-6 text-primary" />,
  title: "Seamless Integrations",
  description: "Designed for easy integration into existing business workflows, allowing SMBs to leverage AI without disrupting their current systems."
}];
export const Features = () => {
  return <section className="bg-navy-light py-[46px]">
      <div className="container px-4">
        <h2 className="text-4xl font-bold text-center mb-16">Key Benefits</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {features.map((feature, index) => <div key={index} className="flex flex-col items-start animate-fade-in border border-white/5 rounded-lg p-6 hover:border-white/10 transition-colors shadow-[0_0_15px_rgba(155,135,245,0.1)] hover:shadow-[0_0_20px_rgba(155,135,245,0.15)]" style={{
          animationDelay: `${index * 0.1}s`
        }}>
              <div className="flex items-center gap-3 mb-3">
                {feature.icon}
                <h3 className="text-xl font-semibold text-primary">{feature.title}</h3>
              </div>
              <p className="text-gray-300">{feature.description}</p>
            </div>)}
        </div>
      </div>
    </section>;
};