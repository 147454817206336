import React, { createContext, useContext, useState, ReactNode } from "react";
import { Folder, Chat } from "../types/index";

interface ChatContextType {
	activeChat: string | null;
	setActiveChat: (chatId: string | null) => void;
	activeFolderId: string | null;
	setActiveFolderId: (folderId: string | null) => void;
	folders: Folder[];
	setFolders: React.Dispatch<React.SetStateAction<Folder[]>>;
	chatsOutsideFolders: Chat[];
	setChatsOutsideFolders: React.Dispatch<React.SetStateAction<Chat[]>>;
	tokenCount: number;
	setTokenCount: (count: number) => void;
	folderChats: Record<string, Chat[]>;
	setFolderChats: React.Dispatch<React.SetStateAction<Record<string, Chat[]>>>;
	subFolders: Record<string, Folder[]>;
	setSubFolders: React.Dispatch<React.SetStateAction<Record<string, Folder[]>>>;
	manageHistory: boolean;
	setManageHistory: (value: boolean) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [activeChat, setActiveChat] = useState<string | null>(null);
	const [activeFolderId, setActiveFolderId] = useState<string | null>(null);
	const [folders, setFolders] = useState<Folder[]>([]);
	const [chatsOutsideFolders, setChatsOutsideFolders] = useState<Chat[]>([]);
	const [tokenCount, setTokenCount] = useState<number>(0);
	const [folderChats, setFolderChats] = useState<Record<string, Chat[]>>({});
	const [subFolders, setSubFolders] = useState<Record<string, Folder[]>>({});

	// ! TO DELETE
	const [manageHistory, setManageHistory] = useState<boolean>(false);

	return (
		<ChatContext.Provider
			value={{
				activeChat,
				setActiveChat,
				activeFolderId,
				setActiveFolderId,
				folders,
				setFolders,
				chatsOutsideFolders,
				setChatsOutsideFolders,
				tokenCount,
				setTokenCount,
				folderChats,
				setFolderChats,
				subFolders,
				setSubFolders,
				manageHistory,
				setManageHistory,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};

export const useChatContext = () => {
	const context = useContext(ChatContext);
	if (context === undefined) {
		throw new Error("useChatContext must be used within a ChatProvider");
	}
	return context;
};
