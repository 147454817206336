import React from "react";
import newFolderIcon from "../../assets/icons/new-folder.png";
// import { Plus } from "lucide-react";

interface AddFolderButtonProps {
	onAddFolder: () => void;
}

const AddFolderButton: React.FC<AddFolderButtonProps> = ({ onAddFolder }) => {
	return (
		<button
			className="rounded-md text-white folder-controls"
			onClick={onAddFolder}
		>
			<img src={newFolderIcon} alt="Folderly" className="w-6" />
		</button>
	);
};

export default AddFolderButton;
