import React, { useState } from 'react';
import { Sparkles, Lock, Shield } from 'lucide-react';
import { SignUpModal } from './SignUpModal';
import { WaitlistModal } from './WaitlistModal';

export const FinalCTA = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);
  
  return <section className="bg-navy-light relative overflow-hidden py-[42px]">
      <div className="container px-4 max-w-4xl mx-auto text-center">
        <div className="animate-fade-in glass-card p-12 relative">
          <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-transparent pointer-events-none" />
          
          <div className="flex justify-center gap-8 mb-6">
            <Sparkles className="w-6 h-6 text-primary animate-pulse" />
            <Lock className="w-6 h-6 text-primary animate-pulse" />
            <Shield className="w-6 h-6 text-primary animate-pulse" />
          </div>
          
          <h2 className="text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-white to-primary">
            Get Started with Haivn – Your Conversations, Your Privacy, Your Control
          </h2>
          
          <div className="flex justify-center gap-4">
            <button className="btn-primary text-lg animate-fade-in hover:scale-105 transition-transform" onClick={() => setIsWaitlistOpen(true)}>
              Join Waitlist
            </button>
            <button className="btn-secondary text-lg animate-fade-in hover:scale-105 transition-transform" onClick={() => setIsSignUpOpen(true)}>
              Partner With Us
            </button>
          </div>
        </div>
      </div>

      <SignUpModal isOpen={isSignUpOpen} onClose={() => setIsSignUpOpen(false)} />
      <WaitlistModal isOpen={isWaitlistOpen} onClose={() => setIsWaitlistOpen(false)} />
    </section>;
};
