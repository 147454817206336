import React from "react";
import { Plus } from "lucide-react";
import { FiEdit } from "react-icons/fi";

interface AddChatButtonProps {
	onAddChat: (folderId?: string | null) => void; // Accept optional folderId
	folderId?: string | null; // Optional folderId prop
	size?: "sm" | "lg";
}

const AddChatButton: React.FC<AddChatButtonProps> = ({
	onAddChat,
	folderId,
	size = "lg",
}) => {
	return (
		<button
			className="rounded-md text-white chat-controls"
			onClick={() => onAddChat(folderId)}
		>
			<FiEdit className="w-6 h-6" />
		</button>
	);
};

export default AddChatButton;
