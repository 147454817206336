import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { ArrowRight, UserPlus, MessageSquare, Repeat } from "lucide-react";
import { SignUpModal } from "./SignUpModal";

const StepCard = ({
	step,
	title,
	description,
	icon: Icon,
}: {
	step: string;
	title: string;
	description: string;
	icon: React.ElementType;
}) => (
	<div className="glass-card p-8 flex flex-col items-start animate-fade-in">
		<div className="flex items-center gap-3 mb-4">
			<Icon className="w-8 h-8 text-primary" />
			<p className="text-primary font-semibold text-lg">{step}</p>
		</div>
		<div>
			<h3 className="text-2xl font-bold mb-3">{title}</h3>
		</div>
		<p className="text-gray-300 leading-relaxed mb-6">{description}</p>
	</div>
);

export const GettingStarted = () => {
	const [isSignUpOpen, setIsSignUpOpen] = useState(false);
	return (
		<section className="relative py-16 px-4 bg-gradient-to-b from-navy/50 to-navy">
			<div className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" />

			<div className="container mx-auto relative">
				<div className="max-w-2xl mx-auto text-center mb-16">
					<h2 className="text-4xl font-bold mb-4">
						Getting Started with Haivn
					</h2>
					<div className="h-1 w-20 bg-primary mx-auto mb-6 rounded-full"></div>
					<p className="text-gray-300 text-lg">
					Seamlessly integrate AI into your business in three simple steps.
					</p>
				</div>

				<div className="grid md:grid-cols-3 gap-8 mb-16">
					<StepCard
						step="Step 1"
						title="Sign Up and Select Your Plan"
						description="Get started effortlessly by selecting a plan that fits your business needs. Whether you're a small business looking for secure AI access or an enterprise needing a customized solution, Haivn offers flexible options to ensure compliance, security, and scalability."
						icon={UserPlus}
					/>
					<StepCard
						step="Step 2"
						title="Engage, Organize & Secure Your AI Conversations"
						description="Once onboard, interact with AI models like OpenAI, Anthropic, and Gemini within a structured, compliant environment. Easily organize chats into folders, track insights, and ensure that all AI interactions remain secure—eliminating risks tied to shadow AI usage."
						icon={MessageSquare}
					/>
					<StepCard
						step="Step 3"
						title="Integrate, Track, and Optimize"
						description="Maximize efficiency by integrating Haivn with your existing business tool. Use built-in audit logs to monitor AI interactions, maintain compliance, and ensure responsible usage across your team. Empower employees with guided AI training and role-based insights to optimize workflows, improve adoption, and drive productivity."
						icon={Repeat}
					/>
				</div>

				<div className="text-center">
					<Button
						className="btn-primary group"
						// onClick={() => setIsSignUpOpen(true)}
					>
						Join Waitlist
						<ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
					</Button>
				</div>
			</div>

			<SignUpModal
				isOpen={isSignUpOpen}
				onClose={() => setIsSignUpOpen(false)}
			/>
		</section>
	);
};
