import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
import { TbLayoutSidebarRightCollapse } from "react-icons/tb";

interface ToggleSidebarProps {
	isOpen: boolean;
	onToggle: () => void;
}

const ToggleSidebarButton: React.FC<ToggleSidebarProps> = ({
	isOpen,
	onToggle,
}) => {
	return (
		<button
			className="rounded-md text-white sidebar-toggle"
			onClick={onToggle}
			aria-label={isOpen ? "Close sidebar" : "Open sidebar"}
		>
			{isOpen ? (
				<TbLayoutSidebarLeftCollapse className="w-[30px] h-[30px]" />
			) : (
				<TbLayoutSidebarRightCollapse className="w-[30px] h-[30px]" />
			)}
		</button>
	);
};

export default ToggleSidebarButton;
