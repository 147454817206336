import React, { useState } from 'react';
import { Dialog, DialogContent } from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import { Input } from "./ui/Input";
import { Textarea } from "../components/ui/textarea";
import { Mail, User, MessageSquare } from 'lucide-react';
import { supabase } from '../integrations/supabase/client';
import { useToast } from "../hooks/use-toast";

interface WaitlistModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WaitlistModal = ({ isOpen, onClose }: WaitlistModalProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!name || !email) {
      toast({
        title: "Error",
        description: "Please provide your name and email.",
        variant: "destructive",
      });
      return;
    }

    setLoading(true);

    try {
      const { error } = await supabase.functions.invoke('send-waitlist-email', {
        body: { name, email, message },
      });

      if (error) throw error;

      toast({
        title: "Success!",
        description: "You've been added to our waitlist. We'll be in touch soon!",
      });
      
      // Reset form and close modal
      setName('');
      setEmail('');
      setMessage('');
      onClose();
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Something went wrong. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-navy-light border-white/10 text-white animate-fade-in">
        <div className="space-y-6">
          <div className="text-center space-y-2">
            <h2 className="text-2xl font-bold">Join Haivn Waitlist</h2>
            <p className="text-gray-400 text-sm">Be the first to try our secure AI conversations</p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name" className="text-gray-200">Name</Label>
              <div className="relative">
                <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <Input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  className="pl-10 bg-white/5 border-white/10 text-white placeholder:text-gray-500"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="email" className="text-gray-200">Email</Label>
              <div className="relative">
                <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="pl-10 bg-white/5 border-white/10 text-white placeholder:text-gray-500"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="message" className="text-gray-200">Message (Optional)</Label>
              <div className="relative">
                <MessageSquare className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <Textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Tell us why you're interested in Haivn"
                  className="pl-10 min-h-[100px] bg-white/5 border-white/10 text-white placeholder:text-gray-500"
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={loading || !name || !email}
              className="w-full bg-primary hover:bg-primary-hover text-white p-3 rounded-lg transition-colors disabled:opacity-50"
            >
              {loading ? 'Submitting...' : 'Join Waitlist'}
            </button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
