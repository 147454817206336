import React, { useState, useRef, useEffect } from "react";
import { Chat, Folder } from "../../types";
import { escapeRegExp } from "../../utils/escapeRegExp";
import { MoreVertical } from "lucide-react";
import ChatItem from "./chat";
import { useChatContext } from "../../context/chatContext";
import FolderMenu from "./FolderMenu";
import { useDrag, useDrop } from "react-dnd";
import { chatService } from "../../services/chat";
import { folderService } from "../../services/folder";
import { auth } from "../../firebase/config";
import logoFolder from "../../assets/icons/new-folder.svg";

import { FaChevronRight, FaChevronDown } from "react-icons/fa";


interface FolderItemProps {
	folder: Folder;
	isExpanded: boolean;
	isLoading: boolean;
	onToggle: (folderId: string) => void;
	onUpdate: (
		folderId: string,
		newName: string,
		emoji: string,
		color: string,
	) => void;
	onDelete: (folderId: string) => void;
	onAddChat: (folderId: string) => void;
	onRenameChat: (chatId: string, newName: string, folderId: string) => void;
	onDeleteChat: (chatId: string, folderId: string) => void;
	highlight?: string; // New prop for search highlighting
	onChangeEmoji: (
		folderId: string,
		name: string,
		newEmoji: string,
		color: string,
	) => void;
	onChangeColor: (
		folderId: string,
		name: string,
		emoji: string,
		newColor: string,
	) => void;
	expandedFolders?: string[];
	fetchFolderChats: (folderId: string) => Promise<void>;
	setFolders: React.Dispatch<React.SetStateAction<Folder[]>>;
	fetchChatsOutsideFolders: () => Promise<void>;
	onMoveFolderToFolder: (
		sourceFolderId: string,
		targetFolderId: string,
	) => Promise<void>;
	selectedChats: string[];
    setSelectedChats: (value: React.SetStateAction<string[]>) => void;
}

const FolderItem: React.FC<FolderItemProps> = ({
	folder,
	isExpanded,
	isLoading,
	onToggle,
	onUpdate,
	onDelete,
	onAddChat,
	onRenameChat,
	onDeleteChat,
	highlight = "", // Default to empty string
	onChangeEmoji,
	onChangeColor,
	expandedFolders = [],
	fetchFolderChats,
	setFolders,
	fetchChatsOutsideFolders,
	onMoveFolderToFolder,
	selectedChats, 
	setSelectedChats
}) => {
	const [isRenaming, setIsRenaming] = useState(false);
	const [newFolderName, setNewFolderName] = useState(folder.name);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);
	const [hasFetchedChats, setHasFetchedChats] = useState(false);
	const {
		folderChats,
		setFolderChats,
		setChatsOutsideFolders,
		subFolders,
		setSubFolders,
	} = useChatContext();

	useEffect(() => {
		const fetchSubFoldersAndChats = async () => {
			//console.log(`Fetching subfolders and chats for folder ${folder.name}`);
			if ((isExpanded || expandedFolders?.includes(folder.id)) && folder.id) {
				const idToken = await auth.currentUser?.getIdToken();
				if (idToken) {
					try {
						const folders = await folderService.getSubFolders(
							folder.id,
							idToken,
						);
						setSubFolders((prevSubFolders) => ({
							...prevSubFolders,
							[folder.id]: folders,
						}));

						const chats = await folderService.getFolderChats(
							folder.id,
							idToken,
						);
						setFolderChats((prev) => ({
							...prev,
							[folder.id]: chats,
						}));
						setHasFetchedChats(true);
					} catch (error) {
						console.error("Failed to fetch folder data:", error);
					}
				}
			}
		};

		fetchSubFoldersAndChats();
	}, [isExpanded, folder.id, expandedFolders]);

	useEffect(() => {
		//console.log(`Folder ${folder.name} updated with chats:`, folder.chats);
	}, [folder.chats, folder.name]);

	// Reset hasFetchedChats when folder is collapsed
	useEffect(() => {
		if (!isExpanded) {
			setHasFetchedChats(false);
		}
	}, [isExpanded]);

	useEffect(() => {
		const handleClickOutsideRename = (event: MouseEvent) => {
			// Cast event.target to HTMLElement to access element information
			const target = event.target as HTMLElement;

			// Check if the click is outside the input field
			const isClickInInput = target.tagName.toLowerCase() === "input";

			if (
				menuRef.current &&
				!menuRef.current.contains(event.target as Node) &&
				isRenaming &&
				newFolderName.trim() &&
				!isClickInInput
			) {
				handleRenameSubmit(event as unknown as React.FormEvent);
				setIsRenaming(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutsideRename);
		return () => {
			document.removeEventListener("mousedown", handleClickOutsideRename);
		};
	}, [isRenaming, newFolderName]);

	const [{ isDragging }, drag] = useDrag({
		type: "FOLDER",
		item: () => ({
			type: "FOLDER",
			folderId: folder.id,
			parentId: folder.parentFolderId,
			name: folder.name,
			emoji: folder.emoji,
			color: folder.color,
			// Include any other properties needed for the folder display
		}),
		end: (item, monitor) => {
			// Clean up if the drop wasn't successful
			if (!monitor.didDrop()) {
				console.log("Folder drag cancelled");
			}
		},
		canDrag: !isRenaming,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const [{ isOver }, drop] = useDrop({
		accept: ["CHAT", "FOLDER"],
		canDrop: (item: any) => {
			// Prevent dropping a folder into itself or its current location
			if (item.type === "FOLDER") {
				return item.folderId !== folder.id && item.parentId !== folder.id;
			}
			return true; // Allow chat drops
		},
		drop: async (item: any, monitor) => {
			if (monitor.didDrop()) return;

			if (item.type === "FOLDER") {
				// Double-check to prevent dropping into same folder
				if (item.folderId === folder.id || item.parentId === folder.id) {
					return;
				}

				console.log("Moving folder:", {
					sourceId: item.folderId,
					targetId: folder.id,
					sourceParentId: item.parentId,
				});

				// Update subFolders state
				setSubFolders((prev) => {
					const newSubFolders = { ...prev };

					// Remove from source folder's subFolders and any other location it might be in
					Object.keys(newSubFolders).forEach((key) => {
						newSubFolders[key] = newSubFolders[key].filter(
							(f) => f.id !== item.folderId,
						);

						// If array is empty after filtering, remove the key entirely
						if (newSubFolders[key].length === 0) {
							delete newSubFolders[key];
						}
					});

					// Add to destination folder's subFolders
					newSubFolders[folder.id] = [
						...(prev[folder.id] || []),
						{
							id: item.folderId,
							name: item.name,
							parentFolderId: folder.id,
							emoji: item.emoji,
							color: item.color,
						},
					];

					return newSubFolders;
				});

				// Update the main folders state
				setFolders((prevFolders) => {
					const updateFoldersRecursively = (folders: Folder[]): Folder[] => {
						return folders.map((f) => {
							// If this is the source parent folder, filter out the moved folder
							if (f.id === item.parentId) {
								return {
									...f,
									subFolders: (f.subFolders || []).filter(
										(sf) => sf.id !== item.folderId,
									),
								};
							}
							// If this is the target folder, add the moved folder
							if (f.id === folder.id) {
								return {
									...f,
									subFolders: [
										...(f.subFolders || []),
										{
											id: item.folderId,
											name: item.name,
											parentId: folder.id,
											emoji: item.emoji,
											color: item.color,
										},
									],
								};
							}
							// If this folder has subfolders, recurse into them
							if (f.subFolders?.length) {
								return {
									...f,
									subFolders: updateFoldersRecursively(f.subFolders),
								};
							}
							return f;
						});
					};

					return updateFoldersRecursively(prevFolders);
				});

				try {
					await onMoveFolderToFolder(item.folderId, folder.id);
					console.log("Folder move completed");
				} catch (error) {
					console.error("Error moving folder:", error);
					// Revert optimistic updates on error
					if (item.parentId) {
						await fetchFolderChats(item.parentId);
					}
					await fetchFolderChats(folder.id);
				}
			} else if (item.type === "CHAT") {
				const updatedChat = { ...item.chat, folderId: folder.id };

				// Optimistically update the UI
				if (item.sourceFolderId) {
					// Remove from source folder
					setFolderChats((prev) => ({
						...prev,
						[item.sourceFolderId]:
							prev[item.sourceFolderId]?.filter(
								(chat) => chat.id !== item.chatId,
							) || [],
					}));
				} else {
					// Remove from global chats
					setChatsOutsideFolders((prev) =>
						prev.filter((chat) => chat.id !== item.chatId),
					);
				}

				// Add to destination folder
				setFolderChats((prev) => ({
					...prev,
					[folder.id]: [...(prev[folder.id] || []), updatedChat],
				}));

				try {
					await chatService.updateChatFolder(item.chatId, folder.id);
				} catch (error) {
					console.error("Error moving chat:", error);
					// Revert optimistic updates
					if (item.sourceFolderId) {
						await fetchFolderChats(item.sourceFolderId);
					} else {
						await fetchChatsOutsideFolders();
					}
					await fetchFolderChats(folder.id);
				}
			}
		},
		collect: (monitor) => ({
			isOver: !!monitor.isOver({ shallow: true }),
		}),
	});

	const handleRenameSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onUpdate(
			folder.id,
			newFolderName,
			folder.emoji || "📁",
			folder.color || "#8B5CF6",
		);
		setIsRenaming(false);
	};

	// Function to highlight the search term
	const getHighlightedText = (text: string, highlight: string) => {
		if (!highlight.trim()) {
			return text;
		}

		// Escape special regex characters in the highlight string
		const escapedHighlight = escapeRegExp(highlight);

		const regex = new RegExp(`(${escapedHighlight})`, "gi");
		const parts = text.split(regex);
		return (
			<>
				{parts.map((part, i) =>
					regex.test(part) ? (
						<span key={i} className="bg-yellow-200">
							{part}
						</span>
					) : (
						part
					),
				)}
			</>
		);
	};

	const handleColorChange = async (
		folderId: string,
		name: string,
		emoji: string,
		newColor: string,
	) => {
		try {
			// Here, onChangeColor will receive the four arguments
			await onChangeColor(folderId, name, emoji, newColor);
			setIsMenuOpen(false); // Optionally close the menu after color selection
		} catch (error) {
			console.error("Error updating folder color:", error);
		}
	};

	const handleEmojiChange = async (
		folderId: string,
		name: string,
		newEmoji: string,
		color: string,
	) => {
		try {
			// Here, onChangeColor will receive the four arguments
			await onChangeEmoji(folderId, name, newEmoji, color);
			setIsMenuOpen(false); // Optionally close the menu after color selection
		} catch (error) {
			console.error("Error updating folder color:", error);
		}
	};

	const handleToggle = (e?: React.MouseEvent) => {
		// Skip toggle if it's from a drop operation
		if (e?.defaultPrevented) return;
		onToggle(folder.id);
		if (!isExpanded) {
			setHasFetchedChats(false);
		}
	};

	// Modify the FolderMenu click handler to force expand if needed
	const handleAddChatFromMenu = (e: React.MouseEvent) => {
		e.stopPropagation(); // Prevent the click from reaching the folder div
		if (!isExpanded) {
			onToggle(folder.id);
		}
		onAddChat(folder.id);
		setIsMenuOpen(false);
	};

	const handleRename = (e: React.MouseEvent) => {
		e.stopPropagation();
		setIsRenaming(true);
		setIsMenuOpen(false);
	};

	const handleDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		onDelete(folder.id);
		setIsMenuOpen(false);
	};

	// Add this useEffect for logging folder and chats

	//quick fix for folder icons. 
	// TODO: update the function to add the folderly logo as default icon for new folders
	const folderIcon = folder.emoji || "📁";

	return (
		<div>
			<div className="mb-4">
				<div
					ref={(node) => drop(drag(node))} // Combine `drag` and `drop` refs
					onClick={handleToggle}
					className={`folder-item p-2 mb-2 cursor-pointer transition duration-200 flex justify-between items-center border-b-2 text-white ${
						isOver ? "bg-purple-200 dark:bg-purple-800" : ""
					} ${isDragging ? "opacity-50" : ""}`}
					style={{ borderColor: folder.color || "#8B5CF6" }} // Use the folder color or default to purple
				>
					<div className="flex items-center flex-grow">
						{(folderChats[folder.id]?.length > 0 || subFolders[folder.id]?.length > 0) && (
							<>
							{isExpanded || expandedFolders?.includes(folder.id) ? (
								
								<FaChevronDown className="h-2 w-2 me-2" />
							) : (
								<FaChevronRight className="h-2 w-2 me-2" />
							)}
							</>
						)}
						<span className="mr-2 text-xl">
							{folderIcon == "📁" ? (
								<img src={logoFolder} alt="Folderly" className="w-6" />
							) : (
								folderIcon
							)}
						</span>
						{/* <span className="mr-2 text-xl">{folder.emoji || "📁"}</span> */}
						{isRenaming ? (
							<form
								onSubmit={handleRenameSubmit}
								className="flex-grow mr-2"
								onClick={(e) => e.stopPropagation()}
							>
								<input
									type="text"
									value={newFolderName}
									onChange={(e) => setNewFolderName(e.target.value)}
									className="w-full px-2 py-1 rounded text-black"
									onKeyDown={(e) => {
										if (e.key === "Enter" && newFolderName.trim()) {
											handleRenameSubmit(e); // Trigger form submission on "Enter"
										}
									}}
									autoFocus
									onClick={(e) => e.stopPropagation()}
								/>
							</form>
						) : (
							<p>{getHighlightedText(folder.name, highlight)}</p>
						)}
					</div>
					<div className="relative" ref={menuRef}>
						<button
							onClick={(e) => {
								e.stopPropagation();
								setIsMenuOpen(!isMenuOpen);
								// onChangeEmoji(folder.id, folder.emoji || "📁");
							}}
							className="text-gray-500 hover:text-white"
						>
							<MoreVertical className="h-5 w-5" />
						</button>
						{isMenuOpen && (
							<FolderMenu
								onAddChat={handleAddChatFromMenu} // Use the new handler
								onRename={handleRename}
								onDelete={handleDelete}
								isLoading={isLoading}
								onChangeEmoji={(newEmoji: string) =>
									handleEmojiChange(
										folder.id,
										folder.name,
										newEmoji,
										folder.color || "#8B5CF6",
									)
								}
								onChangeColor={(newColor: string) =>
									handleColorChange(
										folder.id,
										folder.name,
										folder.emoji || "📁",
										newColor,
									)
								}
								// Pass the color change handler
								selectedColor={folder.color || "#8B5CF6"} // Default or selected color
								selectedEmoji={folder.emoji || "📁"}
							/>
						)}
					</div>
				</div>

				{(isExpanded || expandedFolders?.includes(folder.id)) && (
					<div>
						<div className="ml-4">
							{subFolders[folder.id]?.length > 0 ? (
								subFolders[folder.id].map((subFolder) => (
									<FolderItem
										key={subFolder.id}
										folder={subFolder}
										isExpanded={expandedFolders!.includes(subFolder.id)}
										isLoading={isLoading}
										onToggle={onToggle}
										onUpdate={onUpdate}
										onDelete={onDelete}
										onAddChat={onAddChat}
										onRenameChat={onRenameChat}
										onDeleteChat={onDeleteChat}
										highlight={highlight}
										onChangeEmoji={onChangeEmoji}
										onChangeColor={onChangeColor}
										expandedFolders={expandedFolders}
										fetchFolderChats={fetchFolderChats}
										setFolders={setFolders}
										fetchChatsOutsideFolders={fetchChatsOutsideFolders}
										onMoveFolderToFolder={onMoveFolderToFolder}
										selectedChats={selectedChats}
    									setSelectedChats={setSelectedChats}
									/>
								))
							) : (
								<p className="text-gray-500 italic"></p>
							)}
						</div>
						<div className="ml-4">
							{folderChats[folder.id]?.length > 0 ? (
								folderChats[folder.id].map((chat) => (
									<ChatItem
										key={chat.id}
										parentFolderId={folder.id}
										chat={chat}
										setChatsOutsideFolders={setChatsOutsideFolders}
										fetchFolderChats={fetchFolderChats}
										selectedChats={selectedChats}
    									setSelectedChats={setSelectedChats}
									/>
								))
							) : (
								<p className="text-gray-500 italic"></p>
							)}
						</div>
						{(folderChats[folder.id]?.length > 0 || subFolders[folder.id]?.length > 0) && (
							<div style={{ backgroundColor: folder.color }} className="mt-2 mb-2 h-[1px] w-full opacity-50" />
						)}
					</div>
					
				)}
			</div>
			
		</div>
	);
};

export default FolderItem;
