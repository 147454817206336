import React, { useState } from "react";
import { Plus, Pencil, Trash2, Smile, Palette, Share2 } from "lucide-react";
import EmojiPicker from "emoji-picker-react"; // Import the emoji picker library

interface FolderMenuProps {
	onAddChat: (e: React.MouseEvent) => void;
	onRename: (e: React.MouseEvent) => void;
	onDelete: (e: React.MouseEvent) => void;
	onChangeEmoji: (emoji: string) => void;
	onChangeColor: (color: string) => void;
	isLoading: boolean;
	selectedColor: string;
	selectedEmoji: string;
}

const FolderMenu: React.FC<FolderMenuProps> = ({
	onAddChat,
	onRename,
	onDelete,
	onChangeEmoji,
	onChangeColor,
	isLoading,
	selectedColor,
	selectedEmoji,
}) => {
	const colors = ["#F87171", "#60A5FA", "#34D399", "#FBBF24", "#8B5CF6"]; // Updated color options
	const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false); // State for showing emoji picker

	// Handle emoji selection
	const handleEmojiSelect = (emoji: any, event: any) => {
		console.log("Selected Emoji:", emoji.emoji); // Directly log the emoji value
		onChangeEmoji(emoji.emoji); // Trigger emoji change using the emoji value
		setIsEmojiPickerOpen(false); // Close the emoji picker after selection
	};

	return (
		<div
			className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[9999]"
			onClick={(e) => e.stopPropagation()}
		>
			<div
				className="py-1"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				<button
					onClick={(e) => {
						e.stopPropagation();
						onAddChat(e);
					}}
					className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
				>
					<Plus className="h-4 w-4 inline-block mr-2" /> Add Chat
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
					}}
					disabled={true}
					className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
				>
					<Share2 className="h-4 w-4 inline-block mr-2" /> Share Folder
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						onRename(e);
					}}
					className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
				>
					<Pencil className="h-4 w-4 inline-block mr-2" /> Rename
				</button>

				{/* Emoji Picker */}
				<div className="px-4 py-2 text-sm text-gray-700 relative">
					<button
						onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)} // Toggle emoji picker
						className="flex items-center"
					>
						<Smile className="h-4 w-4 inline-block mr-2" /> Change Emoji
					</button>
					{isEmojiPickerOpen && (
						<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
							<div className="bg-white rounded-lg p-4 shadow-lg">
								<EmojiPicker onEmojiClick={handleEmojiSelect} />{" "}
								{/* Emoji picker */}
								<button
									onClick={() => setIsEmojiPickerOpen(false)}
									className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
								>
									Close
								</button>
							</div>
						</div>
					)}
				</div>

				{/* Color Picker */}
				<div className="px-4 py-2 text-sm text-gray-700">
					<Palette className="h-4 w-4 inline-block mr-2" /> Change Color
					<div className="flex justify-around mt-2">
						{colors.map((color) => (
							<button
								key={color}
								onClick={() => onChangeColor(color)} // Trigger API request to change color
								className={`rounded-full ${
									selectedColor === color ? "w-6 h-6" : "w-4 h-4"
								}`}
								style={{ backgroundColor: color }}
								aria-label={`Change color to ${color}`}
							/>
						))}
					</div>
				</div>

				<button
					onClick={(e) => {
						e.stopPropagation();
						onDelete(e);
					}}
					className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
					disabled={isLoading}
				>
					<Trash2 className="h-4 w-4 inline-block mr-2" /> Delete
				</button>
			</div>
		</div>
	);
};

export default FolderMenu;
