import React, { useState, useEffect } from "react";
import { productService } from "../../services/product/index";
import { loadStripe } from "@stripe/stripe-js";
import ProductCard from "./productCard";

const stripePromise = loadStripe(
	"pk_live_51QCHHSJrLPQdxkp0fscL0Vf2MmqOdTys4jbtybgO75XVAe7Hajbp4PcB615YwqsOqedz8OqN55N96KSW2V4FStWg00lu4vfZxd",
);

interface ProductsProps {
	id?: string;
}

const Products: React.FC<ProductsProps> = ({ id }) => {
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const productsData = await productService.getProducts();
				setProducts(productsData);
			} catch (error) {
				console.error("Error fetching products:", error);
			} finally {
				setLoading(false);
			}
		};
		fetchProducts();
	}, []);

	const handleSubscriptionCheckout = async (productId: string) => {
		const stripe = await stripePromise;
		if (!stripe) {
			console.error("Stripe is not loaded");
			return;
		}

		try {
			const { checkoutUrl } = await productService.createCheckoutSession(
				productId,
			);
			window.open(checkoutUrl, "_blank");
		} catch (error) {
			console.error("Error creating checkout session:", error);
		}
	};

	const handlePurchaseCheckout = async (productId: string) => {
		const stripe = await stripePromise;
		if (!stripe) {
			console.error("Stripe is not loaded");
			return;
		}

		try {
			const { checkoutUrl } = await productService.createOneTimeCheckoutSession(
				productId,
			);
			window.open(checkoutUrl, "_blank");
		} catch (error) {
			console.error("Error creating checkout session:", error);
		}
	};

	const recurringProducts = products.filter(
		(product: any) =>
			product.prices[0]?.interval === "month" ||
			product.prices[0]?.interval === "year",
	);

	const oneTimeProducts = products.filter(
		(product: any) => !product.prices[0]?.interval,
	);

	if (loading) {
		return (
			<div className="min-h-[400px] flex items-center justify-center">
				<div className="animate-pulse text-xl text-gray-600 flex items-center gap-3">
					<div className="w-3 h-3 bg-purple-600 rounded-full animate-bounce"></div>
					<div className="w-3 h-3 bg-purple-600 rounded-full animate-bounce [animation-delay:-.3s]"></div>
					<div className="w-3 h-3 bg-purple-600 rounded-full animate-bounce [animation-delay:-.5s]"></div>
				</div>
			</div>
		);
	}

	return (
		<div className="max-w-[1400px] mx-auto px-4 py-16 sm:px-6 lg:px-8">
			<div className="text-center mb-16">
				<h2 className="text-4xl font-bold text-white mb-4">Choose Your Plan</h2>
				<p className="text-lg text-gray-100 max-w-2xl mx-auto">
					Select the perfect plan for your needs. Whether you're looking for
					continuous access or pay-as-you-go tokens.
				</p>
			</div>

			{/* Subscription Plans Section */}
			{recurringProducts.length > 0 && (
				<div className="mb-16">
					<div className="bg-gradient-to-b from-purple-100 to-white rounded-3xl p-8 mb-8">
						<h3 className="text-2xl font-bold text-purple-900 mb-2 text-center">
							Subscription Plans
						</h3>
						<p className="text-gray-600 text-center mb-8">
							Cancel anytime monthly subscriptions
						</p>
					</div>
					<div className="flex flex-col md:flex-row flex-wrap justify-center gap-8">
						{recurringProducts.map((product: any) => (
							<div
								key={product.id}
								className="w-full md:w-[calc(50%-1rem)] h-full"
							>
								<ProductCard
									product={product}
									handleCheckout={handleSubscriptionCheckout}
									id={id}
								/>
							</div>
						))}
					</div>
				</div>
			)}

			{/* Token Packages Section */}
			{oneTimeProducts.length > 0 && (
				<div>
					<div className="bg-gradient-to-b from-blue-100 to-white rounded-3xl p-8 mb-8">
						<h3 className="text-2xl font-bold text-blue-900 mb-2 text-center">
							AI chat top-ups
						</h3>
						<p className="text-gray-600 text-center mb-8">
							Pay as you go with no recurring commitments
						</p>
					</div>
					<div className="flex flex-col md:flex-row flex-wrap justify-center gap-8">
						{oneTimeProducts.map((product: any) => (
							<div
								key={product.id}
								className="w-full md:w-[calc(50%-1rem)] h-full"
							>
								<ProductCard
									product={product}
									handleCheckout={handlePurchaseCheckout}
									id={id}
								/>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default Products;
