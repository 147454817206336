import React from "react";

interface ProductCardProps {
	product: any;
	handleCheckout: (productId: string) => void;
	id?: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
	product,
	handleCheckout,
	id,
}) => {
	const isRecurring = product.prices[0]?.recurring?.interval;
	const isCurrentPlan = product.id === id;
	const price = product.prices[0].unit_amount / 100;

	return (
		<div
			className={`h-full w-full bg-white rounded-2xl overflow-hidden transform transition-all duration-300 hover:scale-[1.02] flex flex-col
				${
					isCurrentPlan
						? "ring-2 ring-purple-500 shadow-lg"
						: "hover:shadow-xl shadow-md"
				}`}
		>
			{/* Header with slanted bottom */}
			<div className="relative">
				<div
					className={`px-8 pt-8 pb-12 ${
						isRecurring ? "bg-purple-50" : "bg-blue-50"
					}`}
				>
					{isCurrentPlan && (
						<div className="mb-4">
							<span className="px-3 py-1 text-sm font-medium text-[#3D17DD] bg-purple-100 rounded-full">
								Current Plan
							</span>
						</div>
					)}
					<h3 className="text-2xl font-bold text-gray-900 mb-2">
						{product.name}
					</h3>
					<p className="text-gray-600 h-[48px] line-clamp-2">
						{product.description}
					</p>
				</div>
				{/* Slanted overlay */}
				<div
					className={`absolute bottom-0 left-0 right-0 h-12 ${
						isRecurring ? "bg-purple-50" : "bg-blue-50"
					}`}
					style={{
						clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 0)",
						zIndex: 1,
					}}
				/>
				{/* White background to create the angle */}
				<div
					className="absolute bottom-0 left-0 right-0 h-12 bg-white"
					style={{
						clipPath: "polygon(0 0, 100% 100%, 0 100%)",
						zIndex: 2,
					}}
				/>
			</div>

			{/* Pricing */}
			<div className="px-8 py-6 border-b border-gray-100">
				<div className="flex items-baseline">
					<span className="text-4xl font-bold text-gray-900">${price}</span>
					{isRecurring && (
						<span className="text-gray-500 ml-2 text-lg">
							/ {product.prices[0].recurring.interval}
						</span>
					)}
				</div>
			</div>

			{/* Features - This will expand to fill available space */}
			<div className="px-8 py-6 flex-grow">
				<ul className="space-y-4">
					{Object.entries(product.metadata)
						.filter(([key]) => key.toLowerCase().includes("user_point"))
						.map(([key, value]) => (
							<li key={key} className="flex items-start gap-3">
								<svg
									className={`w-5 h-5 mt-0.5 flex-shrink-0 ${
										isRecurring ? "text-purple-500" : "text-blue-500"
									}`}
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="text-gray-700">{value as string}</span>
							</li>
						))}
				</ul>
			</div>

			{/* Button - Always at the bottom */}
			<div className="px-8 pb-8 mt-auto">
				<button
					onClick={() => handleCheckout(product.id)}
					disabled={isCurrentPlan}
					className={`w-full py-4 px-6 rounded-xl text-center text-lg font-semibold transition-all duration-200
						${
							isCurrentPlan
								? "bg-gray-100 text-gray-400 cursor-not-allowed"
								: isRecurring
								? "bg-purple-600 text-white hover:bg-[#3D17DD] shadow-md hover:shadow-lg hover:shadow-purple-100"
								: "bg-blue-600 text-white hover:bg-blue-700 shadow-md hover:shadow-lg hover:shadow-blue-100"
						}`}
				>
					{isRecurring
						? isCurrentPlan
							? "Current Plan"
							: "Subscribe Now"
						: "Purchase Chats"}
				</button>
			</div>
		</div>
	);
};

export default ProductCard;
