import { useEffect, useState } from "react";
import { auth } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import { ChatbotIframe } from "../components/chatbot/chatbot-iframe";

import logo from "../assets/folderly-logo.png";

const Support = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isMobileView, setIsMobileView] = useState(false);

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	const navigate = useNavigate();

	const title = "Support Page";

	// Fetch authentication state
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((currentUser) => {
			setIsAuthenticated(!!currentUser);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		// Function to check the screen size
		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className="min-h-screen h-screen bg-gray-100 dark:bg-gray-900">
			{/* Header Section */}
			{!isMobileView ? (
				<header className="flex items-center justify-between p-6 bg-gray-800 dark:bg-gray-800 text-white shadow-md">
					<img
						src={logo}
						alt="Folderly AI Platform"
						className="w-48 h-auto object-contain"
					/>
					<h1 className="text-2xl font-bold">{title}</h1>
					<button
						onClick={() => navigate("/chat")}
						className="bg-purple-600 hover:bg-[#3D17DD] px-4 py-2 rounded-md text-white font-semibold transition"
					>
						Back to Chats
					</button>
				</header>
			) : (
				<header className="flex flex-col gap-6 items-center justify-between p-6 bg-gray-800 dark:bg-gray-800 text-white shadow-md">
					<div className="flex flex-row items-center justify-between w-full">
						<img
							src={logo}
							alt="Folderly AI Platform"
							className="w-48 h-auto object-contain"
						/>
						<button
							onClick={() => navigate("/chat")}
							className="bg-purple-600 hover:bg-[#3D17DD] px-4 py-2 rounded-md text-white font-semibold transition"
						>
							Back
						</button>
					</div>
					<div>
						<h1 className="text-2xl font-bold">{title}</h1>
					</div>
				</header>
			)}
			<div className="flex flex-col gap-6 md:flex-row md:gap-0 h-[90%]">
				<div className="space-y-6 w-full px-6">
					<p>
						For any assistance, feel free to query the chatbot, which is ready
						to help with your questions and guide you through our platform.
					</p>
					<p>
						If you need further support or prefer direct communication, don’t
						hesitate to reach out to us at&nbsp;
						<a
							href="mailto:support@folderly.ai"
							className="font-bold text-purple-600"
						>
							support@folderly.ai
						</a>
					</p>
				</div>
				<div className="flex w-full justify-center px-6 h-full">
					<div className="w-full md:w-96 md:h-[500px] md:text-center  max-w-4xl h-[470px]">
						<ChatbotIframe />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Support;
