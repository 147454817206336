import { Chat } from "../../types/index";
import { auth } from "../../firebase/config";
import generateFunctionUrl from "../../utils/generateFunctionUrl";

export const chatService = {
	getGlobalChats: async (idToken: string): Promise<Chat[]> => {
		const response = await fetch(generateFunctionUrl("getGlobalChats"), {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (!response.ok) {
			throw new Error("Failed to fetch global chats");
		}

		const data = await response.json();
		return data.chats;
	},
	deleteChat: async (chatId: string, idToken: string) => {
		const response = await fetch(generateFunctionUrl("deleteChat"), {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({ chatId }), // Include chatId in the request body
		});

		if (!response.ok) {
			throw new Error("Failed to delete chat");
		}

		const data = await response.json();
		return data.success;
	},
	deleteMultipleChats: async (chatIds: string[], idToken: string) => {
		for (const chatId of chatIds) {
			if (!chatId) {
				throw new Error("Chat ID is required");
			}
			//delete chat one by one
			const response = await fetch(generateFunctionUrl("deleteChat"), {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify({ chatId }), // Include chatId in the request body
			});
		}

		//return success message
		return "Chats deleted successfully";
	},
	searchChats: async (searchTerm: string, idToken: string): Promise<Chat[]> => {
		try {
			const response = await fetch(generateFunctionUrl("getChatsByName"), {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`, // Ensure token is included
				},
				body: JSON.stringify({ searchTerm }), // Make sure body is properly formatted
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(`Error ${response.status}: ${errorText}`);
			}

			const data = await response.json();
			return data.chats;
		} catch (error) {
			console.error("Error fetching chats by name:", error);
			throw error; // Rethrow error to handle it in the component
		}
	},
	renameChat: async (chatId: string, newName: string, idToken: string) => {
		const response = await fetch(generateFunctionUrl("renameChat"), {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({ chatId, newName }),
		});
		if (!response.ok) {
			throw new Error("Failed to rename chat");
		}

		const data = await response.json();
		return data.success;
	},
	createChat: async (
		name: string,
		idToken: string,
		folderId: string | null,
	) => {
		const response = await fetch(generateFunctionUrl("createChat"), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({ name, folderId }),
		});
		if (!response.ok) {
			throw new Error("Failed to create chat");
		}

		const data = await response.json();
		return data.chatId;
	},
	askAI: async (
		question: string,
		chatId: string,
		idToken: string,
		onChunkReceived: (chunk: string) => void,
		usePromptOptimizer: boolean = false,
	) => {
		const response = await fetch(generateFunctionUrl("askQuestion"), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({ question, chatId, usePromptOptimizer }),
		});

		if (response.status === 403) {
			const errorMessage = await response.text();
			if (errorMessage === "No more tokens") {
				alert(
					"You have reached the maximum number of requests, please top up or wait for the following month.",
				);
			} else {
				alert("You are not authorized to send more requests.");
			}
			throw new Error("No tokens");
		}

		if (!response.ok) {
			throw new Error("Failed to get AI response");
		}

		// Process the chunked response
		const reader = response.body?.getReader();
		const decoder = new TextDecoder();
		let done = false;

		while (!done) {
			const { value, done: readerDone } = await reader!.read();
			done = readerDone;
			const chunkText = decoder.decode(value, { stream: true });
			onChunkReceived(chunkText); // Pass each chunk to the callback
		}
	},

	getMessages: async (chatId: string, idToken: string) => {
		const response = await fetch(generateFunctionUrl("getMessages"), {
			headers: { Authorization: `Bearer ${idToken}` },
			body: JSON.stringify({ chatId }),
		});
		if (!response.ok) {
			throw new Error("Failed to get messages");
		}
		const data = await response.json();
		return data.messages;
	},
	updateChatFolder: async (chatId: string, folderId: string | null) => {
		try {
			const idToken = await auth.currentUser?.getIdToken();

			const response = await fetch(generateFunctionUrl("updateChatFolder"), {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify({
					chatId,
					folderId, // Pass folderId or null to remove the chat from the folder
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to update chat folder");
			}

			const data = await response.json();
			return data;
		} catch (error) {
			console.error("Error updating chat folder:", error);
			throw error;
		}
	},
	saveClip: async (question: string, answer: string, chatId: string) => {
		try {
			const idToken = await auth.currentUser?.getIdToken();

			const response = await fetch(generateFunctionUrl("saveClip"), {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify({ question, answer, chatId }),
			});

			if (!response.ok) {
				throw new Error("Failed to save clip");
			}

			return await response.text(); // Return success message
		} catch (error) {
			console.error("Error saving clip:", error);
			throw error;
		}
	},
};
