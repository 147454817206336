import React, { useState } from "react";
import { Dialog, DialogContent } from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import { Input } from "./ui/Input";
import { Checkbox } from "../components/ui/checkbox";
import { Mail, Lock, LogIn } from "lucide-react";
import { supabase } from "../integrations/supabase/client";
import { useToast } from "../hooks/use-toast";

interface SignUpModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSignInClick?: () => void;
}

export const SignUpModal = ({
	isOpen,
	onClose,
	onSignInClick,
}: SignUpModalProps) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [acceptAge, setAcceptAge] = useState(false);
	const { toast } = useToast();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!acceptTerms || !acceptAge) {
			toast({
				title: "Error",
				description:
					"Please accept the terms and conditions and confirm your age.",
				variant: "destructive",
			});
			return;
		}

		setLoading(true);

		try {
			const { error } = await supabase.auth.signUp({
				email,
				password,
			});

			if (error) throw error;

			toast({
				title: "Success!",
				description: "Please check your email to confirm your account.",
			});
			onClose();
		} catch (error: any) {
			toast({
				title: "Error",
				description: error.message,
				variant: "destructive",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[425px] bg-navy-light border-white/10 text-white animate-fade-in">
				<div className="space-y-6">
					<div className="text-center space-y-2">
						<h2 className="text-2xl font-bold">Sign Up</h2>
						<p className="text-gray-400 text-sm">Join Folderly AI today</p>
					</div>

					<button
						disabled={loading}
						className="w-full flex items-center justify-center gap-2 bg-white text-gray-800 p-3 rounded-lg hover:bg-gray-100 transition-colors disabled:opacity-50"
						onClick={async () => {
							try {
								const { error } = await supabase.auth.signInWithOAuth({
									provider: "google",
								});
								if (error) throw error;
							} catch (error: any) {
								toast({
									title: "Error",
									description: error.message,
									variant: "destructive",
								});
							}
						}}
					>
						<img
							src="/lovable-uploads/160e0b57-8541-4410-857c-121e8141f64a.png"
							alt="Google"
							className="w-12"
						/>
						Sign Up with Google
					</button>

					<div className="relative">
						<div className="absolute inset-0 flex items-center">
							<span className="w-full border-t border-white/10" />
						</div>
						<div className="relative flex justify-center text-xs uppercase">
							<span className="bg-navy-light px-2 text-gray-400">
								Or continue with
							</span>
						</div>
					</div>

					<form onSubmit={handleSubmit} className="space-y-4">
						<div className="space-y-2">
							<Label htmlFor="email" className="text-gray-200">
								Email
							</Label>
							<div className="relative">
								<Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
								<Input
									id="email"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter your email"
									className="pl-10 bg-white/5 border-white/10 text-white placeholder:text-gray-500"
									required
								/>
							</div>
						</div>

						<div className="space-y-2">
							<Label htmlFor="password" className="text-gray-200">
								Password
							</Label>
							<div className="relative">
								<Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
								<Input
									id="password"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									placeholder="Create a password"
									className="pl-10 bg-white/5 border-white/10 text-white placeholder:text-gray-500"
									required
								/>
							</div>
						</div>

						<div className="space-y-3">
							<div className="flex items-center space-x-2">
								<Checkbox
									id="terms"
									checked={acceptTerms}
									onCheckedChange={(checked) =>
										setAcceptTerms(checked as boolean)
									}
									className="border-white/20 data-[state=checked]:bg-primary data-[state=checked]:border-primary"
								/>
								<label htmlFor="terms" className="text-sm text-gray-300">
									I accept the{" "}
									<a href="#" className="text-primary hover:underline">
										Terms and Conditions
									</a>{" "}
									and{" "}
									<a href="#" className="text-primary hover:underline">
										Privacy Policy
									</a>
								</label>
							</div>

							<div className="flex items-center space-x-2">
								<Checkbox
									id="age"
									checked={acceptAge}
									onCheckedChange={(checked) =>
										setAcceptAge(checked as boolean)
									}
									className="border-white/20 data-[state=checked]:bg-primary data-[state=checked]:border-primary"
								/>
								<label htmlFor="age" className="text-sm text-gray-300">
									I confirm that I am at least 18 years old
								</label>
							</div>
						</div>

						<button
							type="submit"
							disabled={loading || !acceptTerms || !acceptAge}
							className="w-full bg-primary hover:bg-primary-hover text-white p-3 rounded-lg transition-colors disabled:opacity-50"
						>
							{loading ? "Signing up..." : "Sign Up"}
						</button>

						<div className="text-center text-sm text-gray-400">
							Already have an account?{" "}
							<button
								onClick={onSignInClick}
								className="text-primary hover:underline inline-flex items-center gap-1"
							>
								Sign In <LogIn className="w-4 h-4" />
							</button>
						</div>
					</form>
				</div>
			</DialogContent>
		</Dialog>
	);
};
