import { useEffect, useState } from "react";
import Products from "../components/products/products";
import { productService } from "../services/product";
import { auth } from "../firebase/config"; // Import Firebase auth
import { useNavigate } from "react-router-dom";

const Account = () => {
	const [data, setData] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		// Add Botpress scripts
		const injectScript = document.createElement("script");
		const contentScript = document.createElement("script");

		injectScript.src = "https://cdn.botpress.cloud/webchat/v2.2/inject.js";
		contentScript.src =
			"https://files.bpcontent.cloud/2024/10/24/13/20241024134328-THCIMES1.js";

		document.body.appendChild(injectScript);
		document.body.appendChild(contentScript);

		// Cleanup on unmount
		return () => {
			document.body.removeChild(injectScript);
			document.body.removeChild(contentScript);
			// Remove the Botpress widget
			const widgetElement = document.getElementById("bp-web-widget-container");
			if (widgetElement) {
				widgetElement.remove();
			}
		};
	}, []);

	useEffect(() => {
		// Use Firebase's onAuthStateChanged to listen for auth changes
		const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
			if (currentUser) {
				console.log("User authenticated:", currentUser);

				try {
					// Get the ID token
					const idToken = await currentUser.getIdToken();

					if (idToken) {
						// Call the service to get user info and product data
						const response = await productService.getUserInfoAndProduct(
							idToken,
						);
						console.log("Response:", response); // Log the response
						setData(response); // Set the data to state
					}
				} catch (err) {
					setError("Failed to fetch user info and product data");
					console.error("Error fetching user info and product data:", err);
				} finally {
					setLoading(false);
				}
			} else {
				console.log("User not authenticated");
				setLoading(false);
			}
		});

		// Cleanup the listener on unmount
		return () => unsubscribe();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}
	const handleUnsubscribe = async () => {
		// Show confirmation dialog
		const confirmed = window.confirm(
			"Are you sure you want to unsubscribe? This will cancel your active subscription and all unused subscription tokens will be lost",
		);

		if (!confirmed) {
			// If the user cancels, do nothing
			return;
		}

		try {
			const result = await productService.unsubscribe();
			console.log(result.message); // "All active subscriptions cancelled and tokens reset."
		} catch (error) {
			console.error("Error during unsubscribe:", error);
		}
	};
	// Check if data exists and if productData is available
	const productId = data?.productData?.productId || "";

	return (
		<div>
			<button
				className="absolute top-4 right-4 px-4 py-2 bg-purple-600 text-white rounded hover:bg-[#3D17DD] transition duration-200"
				onClick={() => window.location.replace("/chat")}
			>
				Back to Chat
			</button>
			{data && data.productData ? (
				<div className="w-full mx-auto flex bg-gray-800 shadow-lg overflow-hidden border border-gray-700">
					<div className="px-6 py-4 bg-gray-900 text-white border-b border-gray-700">
						<h2 className="text-xl font-bold">
							Your Plan: {data?.productData?.productData?.name}
						</h2>
						<button
							className="mt-2 px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded transition duration-200"
							onClick={handleUnsubscribe}
						>
							Unsubscribe
						</button>
					</div>
					<div className="px-6 py-4 text-white">
						<p className="text-lg font-semibold">
							Plan: {data?.productData?.productData?.metadata?.user_point_1}
						</p>
						<p className="text-gray-300 mt-2">
							Remaining Subscription Tokens: {data?.requestTokens}
						</p>
						<p className="text-gray-300 mt-2">
							Remaining Purchased Tokens: {data?.topUpTokens}
						</p>
					</div>
				</div>
			) : (
				<div className="w-full mx-auto flex bg-gray-800 shadow-lg overflow-hidden border border-gray-700">
					<div className="px-6 py-4 bg-gray-900 text-white border-b border-gray-700">
						<h2 className="text-xl font-bold">No Plan Found</h2>
					</div>
					<div className="px-6 py-4 text-white">
						<p className="text-lg font-semibold">
							It looks like you are not subscribed to any plan.
						</p>
						<p className="text-gray-300 mt-2">
							Remaining Subscription Tokens: {data?.requestTokens}
						</p>
						<p className="text-gray-300 mt-2">
							Remaining Purchased Tokens: {data?.topUpTokens}
						</p>
					</div>
				</div>
			)}
			{/* Always render the Products component, even if productId is null */}
			<div className="w-full flex justify-center items-center bg-gray-900">
				<Products id={productId} />
			</div>
		</div>
	);
};

export default Account;
