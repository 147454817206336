import React, { useState } from "react";
import { SignUpModal } from "./SignUpModal";
import { SignInModal } from "./SignInModal";
export const Hero = () => {
	const [isSignUpOpen, setIsSignUpOpen] = useState(false);
	const [isSignInOpen, setIsSignInOpen] = useState(false);
	return (
		<>
			<div className="flex flex-col items-start max-w-2xl animate-fade-in">
				<h1 className="text-5xl md:text-6xl font-bold text-primary mb-6">
					Unlock AI's Potential Without The Risk
				</h1>
				<p className="text-lg md:text-xl text-gray-300 mb-8">
				Haivn makes AI adoption easy and secure for small and medium-sized businesses. With a single subscription, businesses get access to top AI models without the hassle of managing multiple providers. It cuts costs, simplifies integration, and keeps security a priority—so teams can focus on what matters most.
				</p>

				<div className="flex gap-4">
					<button className="btn-primary">
						Join Waitlist!
					</button>
					{/* <button
						className="btn-secondary"
						onClick={() => setIsSignInOpen(true)}
					>
						Log In
					</button> */}
				</div>
			</div>

			<SignUpModal
				isOpen={isSignUpOpen}
				onClose={() => setIsSignUpOpen(false)}
			/>

			<SignInModal
				isOpen={isSignInOpen}
				onClose={() => setIsSignInOpen(false)}
				onSignUpClick={() => {
					setIsSignInOpen(false);
					setIsSignUpOpen(true);
				}}
			/>
		</>
	);
};
