import React from 'react'

interface SignupCheckboxesProps {
    acceptedTerms: boolean;
    setAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
    userMajority: boolean;
    setUserMajority: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignupCheckboxes: React.FC<SignupCheckboxesProps> = ({ acceptedTerms, setAcceptedTerms, userMajority, setUserMajority }) => {
  return (
    <>
        <div className="flex items-baseline gap-3">
            <input
                type="checkbox"
                id="terms"
                checked={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
                className="mt-1.5"
            />
            <label htmlFor="terms" className="text-sm text-gray-300">
                I accept the{" "}
                <a
                    href="/Terms of Service Folderly AI, LLC - Google Docs.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-purple-600 hover:text-purple-300 transition duration-300"
                >
                    Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                    href="/FOLDERLY AI, LLC PRIVACY POLICY - Google Docs.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-purple-600 hover:text-purple-300 transition duration-300"
                >
                    Privacy Policy
                </a>
            </label>
        </div>
        <div className="flex items-baseline gap-3">
            <input
                type="checkbox"
                id="majority"
                checked={userMajority}
                onChange={(e) => setUserMajority(e.target.checked)}
                className="mt-1.5"
            />
            <label htmlFor="terms" className="text-sm text-gray-300">
                I confirm that I am at least 18 years old
                
            </label>
        </div>
    </>
    
  )
}

export default SignupCheckboxes