import React, { useEffect, useState } from 'react';

interface TypewriterTextProps {
  text: string;
  delay?: number;
  showCursor?: boolean;
  startDelay?: number;
}

export const TypewriterText = ({ text, delay = 50, showCursor = true, startDelay = 0 }: TypewriterTextProps) => {
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showDots, setShowDots] = useState(false);

  useEffect(() => {
    const initialDelay = setTimeout(() => {
      setIsTyping(true);
    }, startDelay);

    return () => clearTimeout(initialDelay);
  }, [startDelay]);

  useEffect(() => {
    let currentIndex = 0;
    let dots = '';
    let timeoutId: NodeJS.Timeout;

    const typeText = () => {
      if (currentIndex <= text.length) {
        setDisplayText(text.slice(0, currentIndex));
        currentIndex++;
        timeoutId = setTimeout(typeText, delay);
      } else {
        setIsTyping(false);
        setShowDots(true);
        timeoutId = setTimeout(addDots, delay);
      }
    };

    const addDots = () => {
      if (dots.length < 3) {
        dots += '.';
        setDisplayText(text + dots);
        timeoutId = setTimeout(addDots, delay);
      } else {
        timeoutId = setTimeout(() => {
          setShowDots(false);
          setDisplayText(text);
          currentIndex = 0;
          dots = '';
          timeoutId = setTimeout(() => {
            setDisplayText('');
            setIsTyping(true);
          }, delay * 10);
        }, delay * 10);
      }
    };

    if (isTyping) {
      typeText();
    }

    return () => clearTimeout(timeoutId);
  }, [text, delay, isTyping]);

  return (
    <div className="font-mono min-h-[120px] flex items-start" style={{ minWidth: '100%' }}>
      <span className="whitespace-pre-wrap text-left">
        {displayText || '\u00A0'}
        {showCursor && <span className="animate-pulse">|</span>}
      </span>
    </div>
  );
};