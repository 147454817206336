import React, { useState } from "react";
import { Dialog, DialogContent } from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import { Input } from "./ui/Input";
import { Mail, Lock, UserPlus } from "lucide-react";
import { supabase } from "../integrations/supabase/client";
import { useToast } from "../hooks/use-toast";

interface SignInModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSignUpClick: () => void;
}

export const SignInModal = ({
	isOpen,
	onClose,
	onSignUpClick,
}: SignInModalProps) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const { toast } = useToast();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		try {
			const { error } = await supabase.auth.signInWithPassword({
				email,
				password,
			});

			if (error) throw error;

			toast({
				title: "Success!",
				description: "You have successfully signed in.",
			});
			onClose();
		} catch (error: any) {
			toast({
				title: "Error",
				description: error.message,
				variant: "destructive",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[425px] bg-navy-light border-white/10 text-white animate-fade-in">
				<div className="space-y-6">
					<div className="text-center space-y-2">
						<h2 className="text-2xl font-bold">Sign In</h2>
						<p className="text-gray-400 text-sm">Welcome back to Folderly AI</p>
					</div>

					<button
						disabled={loading}
						className="w-full flex items-center justify-center gap-2 bg-white text-gray-800 p-3 rounded-lg hover:bg-gray-100 transition-colors disabled:opacity-50"
						onClick={async () => {
							try {
								const { error } = await supabase.auth.signInWithOAuth({
									provider: "google",
								});
								if (error) throw error;
							} catch (error: any) {
								toast({
									title: "Error",
									description: error.message,
									variant: "destructive",
								});
							}
						}}
					>
						<img
							src="/lovable-uploads/160e0b57-8541-4410-857c-121e8141f64a.png"
							alt="Google"
							className="w-12"
						/>
						Sign In with Google
					</button>

					<div className="relative">
						<div className="absolute inset-0 flex items-center">
							<span className="w-full border-t border-white/10" />
						</div>
						<div className="relative flex justify-center text-xs uppercase">
							<span className="bg-navy-light px-2 text-gray-400">
								Or continue with
							</span>
						</div>
					</div>

					<form onSubmit={handleSubmit} className="space-y-4">
						<div className="space-y-2">
							<Label htmlFor="email" className="text-gray-200">
								Email
							</Label>
							<div className="relative">
								<Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
								<Input
									id="email"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter your email"
									className="pl-10 bg-white/5 border-white/10 text-white placeholder:text-gray-500"
									required
								/>
							</div>
						</div>

						<div className="space-y-2">
							<Label htmlFor="password" className="text-gray-200">
								Password
							</Label>
							<div className="relative">
								<Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
								<Input
									id="password"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									placeholder="Enter your password"
									className="pl-10 bg-white/5 border-white/10 text-white placeholder:text-gray-500"
									required
								/>
							</div>
						</div>

						<div className="flex justify-end">
							<button
								type="button"
								className="text-primary hover:underline text-sm"
							>
								Forgot password?
							</button>
						</div>

						<button
							type="submit"
							disabled={loading}
							className="w-full bg-primary hover:bg-primary-hover text-white p-3 rounded-lg transition-colors disabled:opacity-50"
						>
							{loading ? "Signing in..." : "Sign In"}
						</button>

						<div className="text-center text-sm text-gray-400">
							Not registered yet?{" "}
							<button
								onClick={onSignUpClick}
								className="text-primary hover:underline inline-flex items-center gap-1"
							>
								Sign Up <UserPlus className="w-4 h-4" />
							</button>
						</div>
					</form>
				</div>
			</DialogContent>
		</Dialog>
	);
};
