import React, { useState, useEffect } from "react";
import Sidebar from "../components/chat/sidebar";
import ChatWindow from "../components/chat/chatWindow";
import Topbar from "../components/chat/topbar";
import { ChatProvider } from "../context/chatContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import Modal from "../components/general/modal";
import ModalProfilecontent from "../components/profile/modal-profile-content";
import { getAuth, updateProfile } from "firebase/auth";
import { Chat, Folder } from "../types/index";
import { auth } from "../firebase/config";
import { folderService } from "../services/folder";

const ChatPage = () => {
    const [isOpen, setIsOpen] = useState(window.innerWidth >= 768);
    const [isModalOpen, setIsModalOpen] = useState(false);
	const [folders, setFolders] = useState<Folder[]>([]);
    const [folderChats, setFolderChats] = useState<Record<string, Chat[]>>({});
    const [userDetails, setUserDetails] = useState({
        userId: "",
        userName: "",
        userEmail: "",
        userPassword: "",
        userProfilePic: "",
    });

    const navigate = useNavigate();

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

	const fetchFolderChats = async (folderId: string) => {
        if (!auth.currentUser) {
            console.error("User not authenticated");
            return;
        }

        try {
            const idToken = await auth.currentUser.getIdToken();
            const chats = await folderService.getFolderChats(folderId, idToken);

            setFolders((prevFolders) => {
                const updateFolderWithChats = (folders: Folder[]): Folder[] => {
                    return folders.map((folder) => {
                        if (folder.id === folderId) {
                            return {
                                ...folder,
                                chats: [...chats], // Create new array reference
                            };
                        }
                        if (folder.subFolders?.length) {
                            return {
                                ...folder,
                                subFolders: updateFolderWithChats(folder.subFolders),
                            };
                        }
                        return folder;
                    });
                };

                return updateFolderWithChats([...prevFolders]); // Create new array reference
            });

            setFolderChats((prev) => ({
                ...prev,
                [folderId]: chats,
            }));
        } catch (error) {
            console.error("Error fetching folder chats:", error);
        }
    };

    const handleProfileUpdate = async (
        userId: string,
        userName: string,
        userEmail: string,
        userPassword: string,
        userProfilePic: string
    ) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            try {
                // Update the user's profile in Firebase
                await updateProfile(user, {
                    displayName: userName,
                    photoURL: userProfilePic,
                });

                // Reload user data to reflect the changes
                await user.reload();

                // Update the local state with the new data
                setUserDetails((prevDetails) => ({
                    ...prevDetails,
                    userName: user.displayName || "",
                    userProfilePic: user.photoURL || "",
                }));

                console.log("Profile updated successfully:", {
                    userId,
                    userName,
                    userEmail,
                    userPassword,
                    userProfilePic,
                });

                closeModal(); // Close the modal after successful update
            } catch (error) {
                console.error("Error updating profile:", error);
            }
        } else {
            console.error("No user is logged in.");
        }
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserDetails({
                    userId: user.uid,
                    userName: user.displayName || "",
                    userEmail: user.email || "",
                    userPassword: "", // Placeholder for the password
                    userProfilePic: user.photoURL || "",
                });
            } else {
                navigate("/");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const sidebarWidth = isOpen ? "w-[24rem]" : "w-[60px] h-[60px]";
    const chatWidth = isOpen
        ? "md:w-[calc(100%-24rem)] w-0"
        : "w-[calc(100%-60px)]";

    return (
        <ChatProvider>
            <Modal isOpen={isModalOpen} onClose={closeModal} title="Profile">
                <ModalProfilecontent
                    userId={userDetails.userId}
                    userName={userDetails.userName}
                    userEmail={userDetails.userEmail}
                    userPassword={userDetails.userPassword}
                    userProfilePic={userDetails.userProfilePic}
                    onProfileUpdate={handleProfileUpdate}
                />
            </Modal>
            <div className="flex h-screen w-screen bg-gray-700">
                <div className={`${sidebarWidth} transition-all duration-300`}>
                    <DndProvider backend={HTML5Backend}>
                        <Sidebar 
							isOpen={isOpen} 
							setIsOpen={setIsOpen} />
                    </DndProvider>
                </div>
                <div
                    className={`${chatWidth} transition-all duration-300 flex flex-col overflow-hidden`}
                >
                    <Topbar openModal={openModal} />
                    <ChatWindow />
                </div>
            </div>
        </ChatProvider>
    );
};

export default ChatPage;
