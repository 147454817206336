// src/services/clip.ts
import { auth } from "../../firebase/config";
import generateFunctionUrl from "../../utils/generateFunctionUrl";

export const clipService = {
	// Function to get all clips for the authenticated user
	getClips: async () => {
		try {
			const idToken = await auth.currentUser?.getIdToken();
			if (!idToken) throw new Error("No ID token found");

			const response = await fetch(generateFunctionUrl("getClips"), {
				headers: {
					Authorization: `Bearer ${idToken}`,
				},
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(
					`Failed to fetch clips: ${response.status} ${errorText}`,
				);
			}

			const data = await response.json();
			return data.clips;
		} catch (error) {
			console.error("Error fetching clips:", error);
			throw error;
		}
	},

	// Function to delete a specific clip by ID
	deleteClip: async (clipId: string) => {
		try {
			const idToken = await auth.currentUser?.getIdToken();
			const response = await fetch(generateFunctionUrl("deleteClip"), {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify({ clipId }),
			});

			if (!response.ok) {
				throw new Error("Failed to delete clip");
			}

			return true; // Successful deletion
		} catch (error) {
			console.error("Error deleting clip:", error);
			throw error;
		}
	},
};
