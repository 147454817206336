import React from 'react';
import { ArrowRight } from 'lucide-react';
import { PromptExample } from './PromptExample';

export const BeforeAfterExamples = () => (
  <div className="space-y-6 max-w-4xl mx-auto">
    <h3 className="text-2xl font-semibold text-center mb-8">
      Before & After Examples
    </h3>
    
    <div className="grid md:grid-cols-2 gap-16 relative">
      <PromptExample
        title="Original Prompt"
        prompt="Write code for a button"
        startDelay={0}
        className="animate-fade-in"
      />
      
      <div className="relative">
        <ArrowRight className="absolute -left-8 top-1/2 -translate-y-1/2 -translate-x-1/2 w-8 h-8 text-primary hidden md:block" />
        <PromptExample
          title="Optimized Prompt"
          prompt="Create a reusable React button component with primary and secondary variants, hover states, and loading state support. Include TypeScript types and follow accessibility best practices."
          startDelay={2000}
          className="animate-fade-in delay-100"
        />
      </div>

      <PromptExample
        title="Original Prompt"
        prompt="Help me with my essay"
        startDelay={8000}
        className="animate-fade-in delay-200"
      />
      
      <div className="relative">
        <ArrowRight className="absolute -left-8 top-1/2 -translate-y-1/2 -translate-x-1/2 w-8 h-8 text-primary hidden md:block" />
        <PromptExample
          title="Optimized Prompt"
          prompt="Please help me improve my 500-word essay on climate change's impact on marine ecosystems. Focus on strengthening the argument structure, enhancing scientific accuracy, and ensuring smooth transitions between paragraphs while maintaining an academic tone."
          startDelay={10000}
          className="animate-fade-in delay-300"
        />
      </div>
    </div>
  </div>
);