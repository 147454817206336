// src/services/productService.ts// Firebase auth for authentication
import { auth } from "../../firebase/config";
import generateFunctionUrl from "../../utils/generateFunctionUrl";

export const productService = {
	getProducts: async () => {
		const idToken = await auth.currentUser?.getIdToken();
		const response = await fetch(generateFunctionUrl("getProducts"), {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (!response.ok) {
			throw new Error("Failed to fetch products");
		}

		return response.json(); // Assume products come in the correct format
	},

	createCheckoutSession: async (productId: string) => {
		const idToken = await auth.currentUser?.getIdToken();

		const response = await fetch(generateFunctionUrl("createCheckoutSession"), {
			method: "POST",
			headers: {
				Authorization: `Bearer ${idToken}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ productId }),
		});
		if (!response.ok) throw new Error("Failed to create checkout session");

		// Parse the response to get the session ID
		const data = await response.json();
		return data; // The response should have sessionId
	},
	createOneTimeCheckoutSession: async (productId: string) => {
		const idToken = await auth.currentUser?.getIdToken();

		const response = await fetch(
			generateFunctionUrl("createOneTimeCheckoutSession"),
			{
				method: "POST",
				headers: {
					Authorization: `Bearer ${idToken}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ productId }), // Send the product ID to the backend
			},
		);
		if (!response.ok)
			throw new Error("Failed to create one-time checkout session");

		// Parse the response to get the session ID and checkout URL
		const data = await response.json();
		return data; // Return sessionId and checkoutUrl
	},
	getUserInfoAndProduct: async (idToken: string) => {
		const response = await fetch(generateFunctionUrl("getUserInfoAndProduct"), {
			method: "GET",
			headers: {
				Authorization: `Bearer ${idToken}`, // Pass the ID token in the Authorization header
			},
		});

		if (!response.ok) {
			throw new Error("Failed to fetch user info and product data");
		}

		return response.json(); // Return the response
	},
	unsubscribe: async () => {
		const idToken = await auth.currentUser?.getIdToken();

		const response = await fetch(
			generateFunctionUrl("cancelAllSubscriptionsAndResetTokens"),
			{
				method: "POST",
				headers: {
					Authorization: `Bearer ${idToken}`,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Failed to unsubscribe from all active subscriptions");
		}

		const data = await response.json();
		return data;
	},
};
