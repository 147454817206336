import React, { useState } from 'react';
import { HelpCircle, ChevronDown } from 'lucide-react';

const faqs = [{
  question: "What is Haivn, and how does it help businesses adopt AI?",
  answer: "Haivn is a secure AI adoption platform that provides businesses with access to multiple large language models (LLMs) through a single login while ensuring data privacy, security, and compliance. Our intuitive interface helps businesses optimize prompts, organize results, and integrate AI seamlessly without the risks of data leakage or compliance violations."
}, {
  question: "How does Haivn improve business efficiency while reducing cybersecurity insurance costs?",
  answer: "Haivn helps businesses become more efficient by streamlining AI adoption, enabling secure and structured AI usage without the risks of shadow AI. By ensuring data security, compliance, and AI governance, Haivn helps businesses reduce cybersecurity risks, which can lead to cyber insurance premium reductions of up to 25%. Our audit logs, access controls, and security protocols make it easier for businesses to meet insurer requirements while enhancing productivity and AI-driven decision-making."
}, {
  question: "How does Haivn ensure security and compliance in AI usage?",
  answer: "Security is our top priority. Haivn prevents AI data leaks by ensuring that LLMs cannot train on user data. Our platform includes enterprise-grade encryption, user permissions, audit logs, and compliance tracking, helping businesses meet regulatory requirements while safely leveraging AI tools."
},{
  question: "What makes Haivn different from other AI platforms?",
  answer: "Unlike most AI aggregators that cater to specific industries or focus on enterprise-level solutions, Haivn is built for small and mid-market businesses. We provide a structured, secure, and easy-to-use AI adoption platform that eliminates the risks of shadow AI usage, security threats, and unstructured implementation—all at an affordable price."
},{
  question: "How much does Haivn cost?",
  answer: `Haivn offers flexible pricing:
Basic Plan – $10 per seat/month
Business Plan – $20 per seat/month
Enterprise - Custom
These plans ensure businesses of all sizes can access secure, compliant, and cost-effective AI tools without breaking the bank.`
},
{
  question: "Can Haivn integrate with my existing tools and workflows?",
  answer: "Yes! Haivn is designed for seamless AI integration with existing business tools. Our platform supports multiple AI models, team collaboration features, and workflow automation, making it easy for businesses to incorporate AI into their daily operations without disruption."
},
{
  question: "Does Haivn offer custom implementation and user training?",
  answer: "Yes! Haivn partners with trusted AI consultants, managed service providers (MSPs), and system integrators to provide custom implementation and hands-on training. Whether you need AI adoption strategies, workflow optimization, or compliance guidance, our partners ensure a smooth and effective rollout tailored to your business needs."
},
{
  question: "How does Haivn work with MSPs, MSSPs, and cybersecurity partners to ensure compliance and support?",
  answer: "Haivn is designed for deployment through Managed Service Providers (MSPs), Managed Security Service Providers (MSSPs), and cybersecurity partners to help businesses adopt AI securely and in compliance with industry regulations. Our partners offer ongoing support, security monitoring, compliance enforcement, and AI governance to ensure that businesses can leverage AI safely while mitigating cyber risks and maintaining regulatory compliance."
},


];
export const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  
  const toggleQuestion = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  
  return (
    <section className="bg-navy py-[51px]">
      <div className="container px-4">
        <div className="flex items-center justify-center gap-3 mb-16">
          <HelpCircle className="w-8 h-8 text-primary" />
          <h2 className="text-4xl font-bold text-center">Frequently Asked Questions</h2>
        </div>
        
        <div className="max-w-3xl mx-auto">
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className="glass-card animate-fade-in cursor-pointer group" 
                style={{
                  animationDelay: `${index * 0.1}s`
                }} 
                onClick={() => toggleQuestion(index)}
              >
                <div className="p-6">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-xl font-semibold text-primary flex items-center gap-2">
                      {faq.question}
                    </h3>
                    <ChevronDown className={`w-5 h-5 transition-transform duration-300 ${expandedIndex === index ? 'rotate-180' : ''}`} />
                  </div>
                  <div className={`overflow-hidden transition-all duration-300 ${expandedIndex === index ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'}`}>
                    <p className="text-gray-300 leading-relaxed">{faq.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
