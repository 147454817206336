import { auth } from "../../firebase/config";
import generateFunctionUrl from "../../utils/generateFunctionUrl";

export const userService = {
	updateAiType: async (
		userId: string,
		aiType: "Gemini" | "GPT 4o" | "o1-preview" | "claude-3",
	) => {
		try {
			// Get the user's ID token from the Firebase Auth client
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}

			const idToken = await currentUser.getIdToken();

			// Make the API call to the Firebase function to update the AI type
			const response = await fetch(generateFunctionUrl("updateAiType"), {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify({
					aiType: aiType,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to update AI type");
			}

			const data = await response.json();
			console.log(data.message);
		} catch (error) {
			console.error("Error updating AI type:", error);
		}
	},
	getUserInfo: async (idToken: string) => {
		try {
			// Make the API call to fetch the user's info from the backend (assume Firestore)
			const response = await fetch(generateFunctionUrl("getUserInfo"), {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch user info");
			}

			const userInfo = await response.json();
			return userInfo; // Return the user information, including aiType
		} catch (error) {
			console.error("Error fetching user info:", error);
			throw new Error("Failed to fetch user info");
		}
	},
	getUserTokens: async () => {
		try {
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}

			const idToken = await currentUser.getIdToken();

			// Make an API call to the Firebase function to fetch the tokens
			const response = await fetch(generateFunctionUrl("getTokens"), {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${idToken}`,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch user tokens");
			}

			const tokenData = await response.json();
			return tokenData.totalTokens; // Return the total token count
		} catch (error) {
			console.error("Error fetching user tokens:", error);
			throw new Error("Failed to fetch user tokens");
		}
	},
};
