import React, { useState, useEffect } from "react";
import ProfilePicture from "./profile-picture";

interface ModalProfilecontentProps {
    userId: string;
    userName?: string;
    userEmail?: string;
    userPassword?: string;
    userProfilePic?: string;
    onProfileUpdate: (
        userId: string,
        userName: string,
        userEmail: string,
        userPassword: string,
        userProfilePic: string
    ) => void;
}

const ModalProfilecontent: React.FC<ModalProfilecontentProps> = ({
    userId,
    userName = "", // Default to empty string
    userEmail = "", // Default to empty string
    userPassword = "", // Default to empty string
    userProfilePic = "", // Default to empty string
    onProfileUpdate,
}) => {
    const [name, setName] = useState(userName);
    const [email, setEmail] = useState(userEmail);
    const [password, setPassword] = useState(userPassword);
    const [profilePic] = useState(userProfilePic); // Profile pic is disabled

    useEffect(() => {
        // Update the state if props change
        setName(userName);
        setEmail(userEmail);
        setPassword(userPassword);
    }, [userName, userEmail, userPassword]);

    const handleClick = () => {
        // Trigger the callback passed from ChatPage
        onProfileUpdate(userId, name, email, password, profilePic);
    };

    return (
        <div className="flex flex-row py-6 space-x-6">
            <div className="w-1/3">
                <div className="flex flex-col justify-between items-center gap-6">
                    <ProfilePicture width="28" height="28" />
                    {/* <button>Change</button> */}
                </div>
            </div>

            <div className="w-2/3 flex">
                <div className="flex flex-col justify-between items-start">
                    <div className="space-y-4 pb-4">
                        <input
                            className="w-full py-1 px-2 rounded-md outline outline-gray-700 outline-1 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Your name"
                            value={name} // Use the state variable
                            onChange={(e) => setName(e.target.value)} // Update state on change
                        />
                        <input
                            className="w-full py-1 px-2 rounded-md outline outline-gray-700 outline-1 
                            focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white
                            opacity-50"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Your email"
                            value={email} // Use the state variable
                            disabled={true}
                        />
                        <input
                            className="w-full 
                                py-1 px-2 rounded-md outline outline-gray-700 outline-1 
                                focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700
                                dark:text-white opacity-50"
                            type="password"
                            name="password"
                            id="password"
                            placeholder="**********"
                            value={password} // Use the state variable
                            disabled={true}
                        />
                    </div>
                    <button
                        onClick={handleClick}
                        className="w-full py-1 px-2 rounded-md outline outline-gray-700 outline-1 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalProfilecontent;
