import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import SignupCheckboxes from "./signup-checkboxes";

interface SocialProps {
    currentAction: string;
    buttonLabel: string;
    }

export const Social = ({currentAction, buttonLabel}: SocialProps) => {
    const navigate = useNavigate();

    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [userMajority, setUserMajority] = useState(false);
    const [error, setError] = useState<string | null>(null);


    const handleGoogle = async () => {
        // Check if the user has accepted the terms and conditions when registering
        if (currentAction === "signup") {

            if (!acceptedTerms) {
                setError("You must accept the terms and conditions to use this service.");
                return
            }

            if (!userMajority) {
                setError("You must be at least 18 years old to use this service.");
                return
            }
        }

        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);

            if (currentAction === "signup") {
                // Create user document in Firestore, if the email is not already registered, otherwise firebase will ignore
                await setDoc(doc(db, "users", result.user.uid), {
                    email: result.user.email,
                    createdAt: new Date(),
                    requestTokens: 10,
                    maxRequestTokens: 10, // Default tokens
                    topUpTokens: 0,
                    folderTokens: 5,
                    acceptedTermsAt: new Date(),
                    userMajorityAt: new Date()
                });
            }

            //console.log(`User signed in with Google: ${result.user.displayName} & currentAction: ${currentAction}`);

            navigate('/chat'); 
        } catch (error) {
            console.error("Google Sign-In Error:", error);
        }
    };
    return (
        <div className="flex flex-col items-center w-full gap-x-2 gap-y-4">
            {currentAction === "signup" && (
                <>
                    {error && (
                        <div className="bg-red-500/10 border border-red-500/50 text-red-200 px-4 py-3 rounded-lg w-full">
                            {error}
                        </div>
                    )}
                    
                    <SignupCheckboxes
                        acceptedTerms={acceptedTerms}
                        setAcceptedTerms={setAcceptedTerms}
                        userMajority={userMajority}
                        setUserMajority={setUserMajority}
                    />
                </>
            )}

            <button
                className=" w-full text-sm text-gray-500 border-gray-600 flex flex-row items-center justify-center gap-2 p-2 rounded-md bg-white dark:bg-gray-800 dark:text-white border"
                onClick={handleGoogle}
            >
                <FcGoogle className="w-6 h-6" />
                <span>{buttonLabel}</span>
            </button>
            <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent mt-2 mb-2 h-[1px] w-full" />
        </div>
    )
}