import React, { useState, useEffect } from "react";
import { auth } from "../../firebase/config";

interface ProfilePictureProps {
	width?: string;
	height?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ width, height }) => {
	const userEmail = auth.currentUser?.email || "";
	const userFirstLetter = userEmail.charAt(0).toUpperCase();
	const userProfilePic =
		auth.currentUser?.photoURL || userEmail.charAt(0).toUpperCase();

	//console.log("User profile picture:", userProfilePic);

	if (!userProfilePic) {
		console.log("No profile picture found");
	}

	const [image, setImage] = useState(userProfilePic);

	useEffect(() => {
		setImage(userProfilePic);
	}, [userProfilePic]);

	return (
		<div
			className={`rounded-full bg-[#3D17DD] flex items-center justify-center  w-${width} h-${height}`}
		>
			{userProfilePic ? (
				<img
					src={userProfilePic}
					alt="Profile Picture"
					className={`rounded-full w-full h-full`}
				/>
			) : (
				<span className="text-white text-xl font-bold">S</span>
			)}
		</div>
	);
};

export default ProfilePicture;
