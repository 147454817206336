import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut, User } from "firebase/auth";
import ProfilePicture from "../profile/profile-picture";
import logo from "../../assets/Haivn_Logo_Finals_Opt 1 White.png";

interface HeaderProps {
	user: User | null;
	setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const Header: React.FC<HeaderProps> = ({ user, setUser }) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();

	// Handle sign out
	const handleSignOut = async () => {
		const auth = getAuth();
		try {
			await signOut(auth);
			console.log("User signed out successfully");
			setUser(null); // Clear the user state
			navigate("/");
		} catch (error) {
			console.error("Error signing out: ", error);
		}
	};

	// Toggle dropdown menu
	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<header className="px-14 pt-4 flex justify-center md:justify-between gap-6">
			<a href="/">
				<img
					src={logo}
					alt="Folderly AI Platform"
					className="w-48 h-auto object-contain"
				/>
			</a>

			{user && (
				<>
					<button
						className="rounded-full bg-[#3D17DD] flex items-center justify-center w-12 h-12"
						onClick={handleToggle}
					>
						<ProfilePicture width="12" height="12" />
					</button>
					<div
						ref={menuRef}
						className={`absolute right-6 w-32 outline outline-1 outline-white rounded-md top-20 px-1 py-4 space-y-2 shadow-xl ${
							!isOpen ? "hidden" : ""
						}`}
					>
						<button className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4">
							<a href="/chat">Chat</a>
						</button>
						<button
							className="w-full rounded-md hover:bg-[#3D17DD] text-white text-left px-4"
							onClick={handleSignOut}
						>
							Sign Out
						</button>
					</div>
				</>
			)}
		</header>
	);
};

export default Header;
