import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
	apiKey: "AIzaSyDrYk62WPlfqO5q9m6fFskWrfu6w8P5aQE",
	authDomain: "folderly-56e60.firebaseapp.com",
	projectId: "folderly-56e60",
	storageBucket: "folderly-56e60.appspot.com",
	messagingSenderId: "479093714577",
	appId: "1:479093714577:web:5dc6e2699e6eebf6101930",
	measurementId: "G-LH20MRWBS4",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const sendPasswordResetEmailFunc = sendPasswordResetEmail;
export const db = getFirestore(app);
export const functions = getFunctions(app);

connectFunctionsEmulator(functions, "localhost", 5001);
