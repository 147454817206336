
import React from 'react';
import { Shield, Bot, Lock, Cpu } from 'lucide-react';

export const SecurityFeatures = () => {
  return (
    <section className="py-16 px-4 bg-navy-light">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 gap-12">
          {/* AI Models Card */}
          <div className="glass-card p-12 animate-fade-in group hover:border-primary/30 transition-colors">
            <div className="flex items-center gap-4 mb-6">
              <div className="p-3 rounded-xl bg-primary/10 text-primary">
                <Cpu className="w-8 h-8" />
              </div>
              <Bot className="w-8 h-8 text-primary" />
            </div>
            <h2 className="text-3xl font-bold mb-6">
              Powered by the Best AI Models
            </h2>
            <p className="text-gray-300 leading-relaxed mb-8">
              Haivn conversations are powered by OpenAI's latest GPT-4o and GPT-o1 models,
              Anthropic's Claude 3.5 and Gemini Pro 1.5, giving you free access to the best in AI technology.
              Whether you're drafting ideas, researching, or brainstorming, Haivn brings you the power
              of multiple advanced LLMs right at your fingertips.
            </p>
            <button className="btn-primary">
              Start chatting with GPT-4o, GPT-o1, Claude 3.5 & Gemini Pro 1.5 today
            </button>
          </div>

          {/* Security Card */}
          <div className="glass-card p-12 animate-fade-in group hover:border-primary/30 transition-colors">
            <div className="flex items-center gap-4 mb-6">
              <div className="p-3 rounded-xl bg-primary/10 text-primary">
                <Shield className="w-8 h-8" />
              </div>
              <Lock className="w-8 h-8 text-primary" />
            </div>
            <h2 className="text-3xl font-bold mb-6">
              Privacy and Security You Can Trust
            </h2>
            <p className="text-gray-300 leading-relaxed mb-8">
            Haivn prevents AI data leaks by ensuring that LLMs cannot train on user data. Our platform includes enterprise-grade encryption, user permissions, audit logs, and compliance tracking, helping businesses meet regulatory requirements while safely leveraging AI tools. SMBs can achieve 10-25% cybersecurity insurance savings.
            </p>
            <p className="text-gray-300 leading-relaxed">
              Enjoy peace of mind knowing your professional conversations remain private,
              secure, and exclusively yours.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
