import React, { useState, useEffect } from "react";

const Tooltip: React.FC<{ text: string; children: React.ReactNode }> = ({
    text,
    children,
}) => {
    const [hovering, setHovering] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;

        if (hovering) {
            // Set a timer to show the tooltip after 1 second
            timer = setTimeout(() => {
                setVisible(true);
            }, 1000);
        } else {
            // Clear the timer if user stops hovering
            setVisible(false);
            if (timer) {
                clearTimeout(timer);
            }
        }

        return () => clearTimeout(timer);
    }, [hovering]);

    return (
        <div
            className="relative flex items-center"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            {children}
            {visible && (
                <div className="absolute text-center top-full mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-lg z-10 border border-1 border-gray-600 transition-opacity duration-300 opacity-100">
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
